import React from 'react';

import Input from '../simple_components/Input.jsx';
import Selector from '../components/Selector.jsx';
import Button from '../simple_components/Button.jsx';

import '../styles/Form.scss';

function Form(props){
    return(
        <div className={props.className}>
            {props.fields.map((field,index)=>(
                field.category==='input'?
                    <Input key={index} {...field.input} />
                    :
                    field.category==='selector'?
                        <Selector key={index} {...field.selector} />
                        :
                        field.category==='submit'?
                            <Button key={index} {...field.button} />
                            :null
            ))}
        </div>
    );
}

export default Form;