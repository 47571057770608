import React from 'react';

import Header from '../containers/Header.jsx';
import Navigator from '../containers/Navigator.jsx';
import Footer from '../containers/Footer.jsx';
import Text from '../simple_components/Text.jsx';

import '../styles/ReteVendita.scss';

function ReteVendita(props){
    return(
        <>
            <Header setLanguage={props.setLanguage} {...props.header} />
            <Navigator {...props.navigator} />
            <div id={props.id} className={props.className}>
                <Text {...props.text} />
            </div>
            <Footer {...props.footer} />
        </>
    );
}

export default ReteVendita;