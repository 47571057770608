import React from 'react';

import Image from '../simple_components/Image.jsx';
import Text from '../simple_components/Text.jsx';

import '../styles/Categoria.scss';

function Categoria(props){
    return(
        <div className={props.className}>
            <div className={props.container_className}>
                <Image {...props.image} />
            </div>
            <Text {...props.text} />
        </div>
    );
}

export default Categoria;