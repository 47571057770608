import React,{useState} from 'react';

function Input(props){
    const [value,setValue]=useState('');

    return(
        <>
            {props.placeholder}
            <input id={props.id} type={props.type} value={value} className={props.className} pattern={props.pattern!==undefined?props.pattern:null} required={props.require!==undefined?props.required:null} onChange={e=>setValue(e.target.value)} />
        </>
    );
}

export default Input;