import React from 'react';

import history from '../history.js';

import '../styles/Slide.scss';

function Slide(props){
    const styles = {
        backgroundImage: `url(${props.image_src})`
    }

    return(
        <div id={props.id} className={props.className} onClick={(e)=>{if(props.href!==undefined){history.push(props.href);}else if(props.external_href!==undefined){window.location.href=props.external_href;}}} style={styles}></div>
    );
}

export default Slide;