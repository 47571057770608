import React from 'react';

import Header from '../containers/Header.jsx';
import Navigator from '../containers/Navigator.jsx';
import Footer from '../containers/Footer.jsx';
import Image from '../simple_components/Image.jsx';
import Text from '../simple_components/Text.jsx';

import '../styles/StoriaAzienda.scss';

function StoriaAzienda(props){
    return(
        <>
            <Header {...props.header} setLanguage={props.setLanguage} />
            <Navigator {...props.navigator} />
            <div className={props.className}>
                <Image {...props.image} />
                <Text {...props.text_title} />
                <Text {...props.text_content} />
            </div>
            <Footer />
        </>
    );
}

export default StoriaAzienda;