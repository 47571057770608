import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft,faAngleRight } from '@fortawesome/free-solid-svg-icons';

import '../styles/Arrow.scss';

function Arrow(props){
    switch(props.type){
        case 'left':{
            return(
                <div id={props.id} className={props.className/*'backArrow arrow'*/} onClick={props.onClick}>
                    <FontAwesomeIcon icon={faAngleLeft} size='2x' />
                </div>
            );
        }
        case 'right':{
            return(
                <div id={props.id} className={props.className/*'nextArrow arrow'*/} onClick={props.onClick}>
                    <FontAwesomeIcon icon={faAngleRight} size='2x' />
                </div>
            );
        }
        default:{return null;}
    }
}

export default Arrow;