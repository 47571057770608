import React from 'react';

import Header from '../containers/Header.jsx';
import Navigator from '../containers/Navigator.jsx';
import Footer from '../containers/Footer.jsx';
import Text from '../simple_components/Text.jsx';

import '../styles/Contatti.scss';

function Contatti(props){
    return(
        <>
            <Header setLanguage={props.setLanguage} {...props.header} />
            <Navigator {...props.navigator} />
            <div id={props.id} className={props.className}>
                <div className={props.container_className}>
                    <div className={props.left_column_className}>
                        <Text {...props.text[0]} />
                        <Text {...props.text[1]} />
                        <Text {...props.text[2]} />
                        <Text {...props.text[3]} />
                        <Text {...props.text[4]} />
                    </div>
                    <div className={props.right_column_className}>
                        <Text {...props.text[5]} />
                        <Text {...props.text[6]} />
                        <Text {...props.text[7]} />
                        <Text {...props.text[8]} />
                        <Text {...props.text[9]} />
                    </div>
                </div>
                <div className={props.center_column_className}>
                    <Text {...props.text[10]} />
                    <Text {...props.text[11]} />
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Contatti;