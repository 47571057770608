import React from 'react';

import Header from '../containers/Header.jsx';
import Navigator from '../containers/Navigator.jsx';
import Footer from '../containers/Footer.jsx';
import Text from '../simple_components/Text.jsx';
import Form from '../components/Form.jsx';

import '../styles/DownloadCataloghi.scss';

function DownloadCataloghi(props){
    return(
        <>
            <Header setLanguage={props.setLanguage} {...props.header} />
            <Navigator {...props.navigator} />
            <div id={props.id} className={props.className}>
                <Text {...props.text_title} />
                <Text {...props.text_description} />
                <Form {...props.form} />
            </div>
            <Footer {...props.footer} />
        </>
    );
}

export default DownloadCataloghi;