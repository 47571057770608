import React,{useState} from 'react';

import Arrow from '../simple_components/Arrow.jsx';
import Slide from '../simple_components/Slide.jsx';

import '../styles/Slideshow.scss';

function Slideshow(props){
    const [active,setActive]=useState(0);
    const [translateValue,setTranslateValue]=useState(0);

    function goToPrevSlide(){
        if(active === 0)
          return;
        
        setActive(active-1);
        setTranslateValue(translateValue+slideWidth());
    }

    function goToNextSlide(){
        if(active === props.slides.length - 1) {
            setActive(0);
            setTranslateValue(0);
        }else{
            setActive(active+1);
            setTranslateValue(translateValue-slideWidth());
        }
    }

    function slideWidth(){
        return document.querySelector('.slide_'+props.className).clientWidth;
    }

    return(
        <div id={props.id} className={props.className}>
            <div className={props.wrapper_className}
              style={{
                transform:'translateX('+translateValue+'px)',
                transition:'transform ease-out 0.45s'
              }}>
                {
                  props.slides.map((slide,i)=>(
                    <Slide key={i} {...slide} />
                  ))
                }
            </div>
            <Arrow
                {...props.arrow_left}
                onClick={goToPrevSlide}
            />
            <Arrow
                {...props.arrow_right}
                onClick={goToNextSlide}
            />
        </div>
    );
}

export default Slideshow;