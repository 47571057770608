import React from 'react';

import Header from '../containers/Header.jsx';
import Footer from '../containers/Footer.jsx';
import Navigator from '../containers/Navigator.jsx';
import ItemList from '../containers/Itemlist.jsx';

import '../styles/ProdottiCategoria.scss';

function ProdottiCategoria(props){
    return(
        <div id={props.id} className={props.className}>
            <Header {...props.header} setLanguage={props.setLanguage} />
            <Navigator {...props.navigator} />
            <div className={props.container_className}>
                {props.prodotti.map((prodotto,index)=>(<ItemList key={index} {...prodotto} />))}
            </div>
            <Footer />
        </div>
    );
}

export default ProdottiCategoria;