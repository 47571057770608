import Axios from 'axios';

const host='http://www.linfadesign.com';

export default function(language){
    return{
        homepage:homepage(language),
        categorie_prodotto:categorieProdotto(language),
        prodotti_categoria:function(categoria){return prodottiCategoria(language,categoria);},
        prodotto_detail:function(categoria,prodotto){return prodottoDetail(language,categoria,prodotto);},
        storia_azienda:storiaAzienda(language),
        novita:novita(language),
        eventi:eventiList(language),
        novita_prodotti:novitaProdotti(language),
        novita_dettaglio:function(novita){return novitaDettaglio(language,novita);},
        evento_dettaglio:function(evento){return eventoDettaglio(language,evento);},
        press:press(language),
        download:downloadCataloghi(language),
        contatti:contattiInfo(language),
        rete_vendita:reteVendita(language),
        richiesta_contatti:richiestaContatti(language),
        download_cataloghi:download(language),
        richiesta_contatti_success:richiestaContattiSuccess(language)
    };
};

function homepage(language){
    return{
        id:'homepage_view',
        className:'homepage',
        container_className:'homepage_images',
        header:header(language),
        slideshow:{
            id:'slideshow_homepage',
            className:'slideshow_homepage',
            wrapper_className:'slideshow_homepage_wrapper',
            arrow_left:{
                id:'arrow_slideshow_homepage_left',
                className:'arrow_slideshow_homepage left',
                type:'left'
            },
            arrow_right:{
                id:'arrow_slideshow_homepage_right',
                className:'arrow_slideshow_homepage right',
                type:'right'
            },
            slides:[
                {
                    id:'slide_slideshow_homepage_0',
                    className:'slide_slideshow_homepage',
                    image_src:'./slideshow/homepage/0.png',
                    href:'/novita/prodotti'
                },
                {
                    id:'slide_slideshow_homepage_1',
                    className:'slide_slideshow_homepage',
                    image_src:'./slideshow/homepage/1.png',
                    external_href:'https://www.youtube.com/watch?v=aLdkWO4IPEY'
                },
                {
                    id:'slide_slideshow_homepage_2',
                    className:'slide_slideshow_homepage',
                    image_src:'./slideshow/homepage/2.png',
                    external_href:'https://www.youtube.com/watch?v=EGDn70cpSB4&feature=youtu.be'
                }
            ]
        },
        left_image:{
            id:'image_homepage_left_image',
            className:'image_homepage_left_image',
            src:'./images/homepage/left.png',
            alt:alt_homepage_left_image[language],
            href:'/press'
        },
        right_image:{
            id:'image_homepage_right_image',
            className:'image_homepage_right_image',
            src:'./images/homepage/right.png',
            alt:alt_homepage_right_image[language],
            href:'/contatti/rete-vendita'
        }
    };
}

function categorieProdotto(language){
    return{
        id:'categorie_prodotto',
        className:'categorie_prodotto',
        container_className:'categorie_prodotto_content',
        header:header(language),
        navigator:{
            id:'navigator',
            className:'navigator',
            path:[
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'HOME',
                    type:'p',
                    href:'/'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'PRODOTTI':'PRODUCTS'}`,
                    type:'p',
                    href:'/prodotti'
                }
            ]
        },
        categorie:categorie.map((categoria,index)=>{
            return{
                id:`categoria_${index}`,
                className:'categoria',
                container_className:'categoria_image',
                image:{
                    id:'image_categoria_'+index,
                    className:'image_categoria',
                    alt:categoria[language],
                    src:`/images/categorie/${categoria.src}.png`,
                    href:`/prodotti/${categoria.src}`
                },
                text:{
                    id:`text_categoria_${index}`,
                    className:'text_categoria',
                    content:categoria[language],
                    type:'p'
                }
            };
        })
    };
}

function prodottiCategoria(language,categoria){
    return{
        id:'prodotti_categoria',
        className:'prodotti_categoria',
        container_className:'prodotti_categoria_content',
        header:header(language),
        navigator:{
            id:'navigator',
            className:'navigator',
            path:[
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'HOME',
                    type:'p',
                    href:'/'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'PRODOTTI':'PRODUCTS'}`,
                    type:'p',
                    href:'/prodotti'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'\u2003>\u2003'+categoria.toUpperCase(),
                    type:'p',
                    href:'/prodotti/'+categoria
                }
            ]
        },
        prodotti:prodotto[categoria].map((item,index)=>{
            return{
                id:`prodotto_${index}`,
                className:'prodotto',
                container_className:'prodotto_text_container',
                image:{
                    id:`image_prodotto_${index}`,
                    className:'image_prodotto',
                    src:`/images/categorie/${categoria}/${item.href}/0.jpg`,
                    href:`/prodotti/${categoria}/${item.href}`,
                    alt:item.title[language]
                },
                text:{
                    id:`text_prodotto_${index}`,
                    className:'text_prodotto',
                    content:item.title[language],
                    type:'p'
                }
            };
        })
    };
}

function prodottoDetail(language,categoria,prodotto){
    let prodotto_item=getProdotto(language,categoria,prodotto);
    return{
        id:'prodotto_dettail',
        className:'prodotto_detail',
        container_className:'prodotto_detail_container',
        header:header(language),
        navigator:{
            id:'navigator',
            className:'navigator',
            path:[
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'HOME',
                    type:'p',
                    href:'/'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'PRODOTTI':'PRODUCTS'}`,
                    type:'p',
                    href:'/prodotti'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'\u2003>\u2003'+categoria.toUpperCase(),
                    type:'p',
                    href:'/prodotti/'+categoria
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'\u2003>\u2003'+prodotto_item.title[language],
                    type:'p',
                    href:'/prodotti/'+categoria+'/'+prodotto_item.href
                }
            ]
        },
        column_className:'prodotto_detail_column',
        prodotto_title_div_className:'prodotto_title_div',
        prodotto_title_text:{
            id:'text_title_prodotto_detail',
            className:'text_title_prodotto_detail',
            content:prodotto_item.title[language],
            type:'h2'
        },
        prodotto_description_div_className:'prodotto_description_div',
        prodotto_description_text:{
            id:'text_description_prodotto_detail',
            className:'text_description_prodotto_detail',
            content:prodotto_item.descrizione[language],
            type:'p'
        },
        prodotto_table_div_className:'prodotto_table_div',
        prodotto_table:{
            id:'table_prodotto_detail',
            className:'table_prodotto_detail',
            data:[],
        },
        prodotto_main_photo_div_className:'prodotto_main_photo_div',
        prodotto_main_photo:{
            id:'image_main_prodotto_detail',
            className:'image_main_prodotto_detail',
            src:`/images/categorie/${categoria}/${prodotto}/0.jpg`
        },
        prodotto_thumbnails_div_className:'prodotto_thumbnails_div',
        thumbnails:prodotto_item.thumbnails.map((thumbnail)=>{
            return{
                id:'image_thumbnail_prodotto_detail_'+thumbnail,
                className:'image_thumbnail_prodotto_detail',
                src:`/images/categorie/${categoria}/${prodotto}/${thumbnail}.jpg`,
                alt:prodotto+' '+thumbnail
            }
        })
    };
}

function storiaAzienda(language){
    return{
        id:'storia_azienda',
        className:'storia_azienda',
        header:header(language),
        navigator:{
            id:'navigator',
            className:'navigator',
            path:[
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'HOME',
                    type:'p',
                    href:'/'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'AZIENDA':'COMPANY'}`,
                    type:'p',
                    href:'/azienda'
                }
            ]
        },
        image:{
            id:'image_storia_azienda',
            className:'image_storia_azienda',
            src:'/images/azienda.jpg',
            alt:'LinfaDesign'
        },
        text_title:{
            id:'text_title_storia_azienda',
            className:'text_title_storia_azienda',
            content:storia_azienda[language].title,
            type:'h2'
        },
        text_content:{
            id:'text_content_storia_azienda',
            className:'text_content_storia_azienda',
            content:storia_azienda[language].content,
            type:'p'
        }
    };
}

function novita(language){
    return{
        id:'novita',
        className:'novita_content',
        header:header(language),
        navigator:{
            id:'navigator',
            className:'navigator',
            path:[
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'HOME',
                    type:'p',
                    href:'/'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'NOVITÀ':'NEWS'}`,
                    type:'p',
                    href:'/novita'
                }
            ]
        },
        novita:[
            {
                className:'novita',
                container_className:'text_novita_div',
                text:{
                    id:'text_novita',
                    className:'text_novita',
                    content:novita_database[0][language],
                    type:'p',
                    href:'/novita/prodotti/tavolo-london'
                },
                image:{
                    id:'image_novita',
                    className:'image_novita',
                    src:'/images/novita/prodotti/tavolo-london/0.jpg',
                    alt:'news_0'
                }
            },
            {
                className:'novita',
                container_className:'text_novita_div',
                text:{
                    id:'text_novita',
                    className:'text_novita',
                    content:novita_database[1][language],
                    type:'p',
                    href:'/novita/prodotti/tavolo-alicante'
                },
                image:{
                    id:'image_novita',
                    className:'image_novita',
                    src:'/images/novita/prodotti/tavolo-alicante/0.jpg',
                    alt:'news_1'
                }
            },
            {
                className:'novita',
                container_className:'text_novita_div',
                text:{
                    id:'text_novita',
                    className:'text_novita',
                    content:novita_database[2][language],
                    type:'p',
                    href:'/novita/prodotti/tavolo-bridge'
                },
                image:{
                    id:'image_novita',
                    className:'image_novita',
                    src:'/images/novita/prodotti/tavolo-bridge/0.jpg',
                    alt:'news_2'
                }
            },
            {
                className:'novita',
                container_className:'text_novita_div',
                text:{
                    id:'text_novita',
                    className:'text_novita',
                    content:novita_database[3][language],
                    type:'p',
                    href:'/novita/eventi/salone-internazionale-del-mobile-2016'
                },
                image:{
                    id:'image_novita',
                    className:'image_novita',
                    src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/0.jpg',
                    alt:'news_3'
                }
            },
            {
                className:'novita',
                container_className:'text_novita_div',
                text:{
                    id:'text_novita',
                    className:'text_novita',
                    content:novita_database[4][language],
                    type:'p',
                    href:'/novita/eventi/salone-internazionale-del-mobile-2015'
                },
                image:{
                    id:'image_novita',
                    className:'image_novita',
                    src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/0.jpg',
                    alt:'news_4'
                }
            },
            {
                className:'novita',
                container_className:'text_novita_div',
                text:{
                    id:'text_novita',
                    className:'text_novita',
                    content:novita_database[5][language],
                    type:'p',
                    href:'/novita/prodotti/tavolo-allungabile-kinesis'
                },
                image:{
                    id:'image_novita',
                    className:'image_novita',
                    src:'/images/novita/prodotti/tavolo-allungabile-kinesis/0.jpg',
                    alt:'news_5'
                }
            },
            {
                className:'novita',
                container_className:'text_novita_div',
                text:{
                    id:'text_novita',
                    className:'text_novita',
                    content:novita_database[6][language],
                    type:'p',
                    href:'/novita/prodotti/poltroncina-mia'
                },
                image:{
                    id:'image_novita',
                    className:'image_novita',
                    src:'/images/novita/prodotti/poltroncina-mia/0.jpg',
                    alt:'news_6'
                }
            },
            {
                className:'novita',
                container_className:'text_novita_div',
                text:{
                    id:'text_novita',
                    className:'text_novita',
                    content:novita_database[7][language],
                    type:'p',
                    href:'/novita/prodotti/tavolo-sintesi'
                },
                image:{
                    id:'image_novita',
                    className:'image_novita',
                    src:'/images/novita/prodotti/tavolo-sintesi/0.jpg',
                    alt:'news_7'
                }
            },
            {
                className:'novita',
                container_className:'text_novita_div',
                text:{
                    id:'text_novita',
                    className:'text_novita',
                    content:novita_database[8][language],
                    type:'p',
                    href:'/novita/prodotti/cose-di-casa-2010'
                },
                image:{
                    id:'image_novita',
                    className:'image_novita',
                    src:'/images/novita/prodotti/cose-di-casa-2010/0.jpg',
                    alt:'news_8'
                }
            },
            {
                className:'novita',
                container_className:'text_novita_div',
                text:{
                    id:'text_novita',
                    className:'text_novita',
                    content:novita_database[9][language],
                    type:'p',
                    href:'/novita/prodotti/gagu-magazine-korea'
                },
                image:{
                    id:'image_novita',
                    className:'image_novita',
                    src:'/images/novita/prodotti/gagu-magazine-korea/0.jpg',
                    alt:'news_9'
                }
            },
            {
                className:'novita',
                container_className:'text_novita_div',
                text:{
                    id:'text_novita',
                    className:'text_novita',
                    content:novita_database[10][language],
                    type:'p',
                    href:'/novita/prodotti/cose-di-casa-2011'
                },
                image:{
                    id:'image_novita',
                    className:'image_novita',
                    src:'/images/novita/prodotti/cose-di-casa-2011/0.jpg',
                    alt:'news_10'
                }
            },
            {
                className:'novita',
                container_className:'text_novita_div',
                text:{
                    id:'text_novita',
                    className:'text_novita',
                    content:novita_database[13][language],
                    type:'p',
                    href:'/novita/eventi/salone-internazionale-del-mobile-2011'
                },
                image:{
                    id:'image_novita',
                    className:'image_novita',
                    src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/0.jpg',
                    alt:'news_13'
                }
            },
            {
                className:'novita',
                container_className:'text_novita_div',
                text:{
                    id:'text_novita',
                    className:'text_novita',
                    content:novita_database[12][language],
                    type:'p',
                    href:'/novita/eventi/salone-internazionale-del-mobile-2010'
                },
                image:{
                    id:'image_novita',
                    className:'image_novita',
                    src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/0.jpg',
                    alt:'news_12'
                }
            },
            {
                className:'novita',
                container_className:'text_novita_div',
                text:{
                    id:'text_novita',
                    className:'text_novita',
                    content:novita_database[11][language],
                    type:'p',
                    href:'/novita/eventi/salone-internazionale-del-mobile-2009'
                },
                image:{
                    id:'image_novita',
                    className:'image_novita',
                    src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/0.jpg',
                    alt:'news_11'
                }
            }
        ]
    };
}

function eventiList(language){
    return{
        id:'eventi_list',
        className:'eventi_list',
        container_className:'text_title_eventi_list_div',
        header:header(language),
        navigator:{
            id:'navigator',
            className:'navigator',
            path:[
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'HOME',
                    type:'p',
                    href:'/'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'NOVITÀ':'NEWS'}`,
                    type:'p',
                    href:'/novita'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'EVENTI':'EVENTS'}'`,
                    type:'p',
                    href:'/novita/eventi'
                }
            ]
        },
        eventi:[
            {
                text:{
                    id:'text_title_eventi_list',
                    className:'text_title_eventi_list',
                    content:'SALONE INTERNAZIONALE DEL MOBILE 2016',
                    type:'h2'
                },
                slideshow:{
                    id:'slideshow_eventi',
                    className:'slideshow_eventi',
                    wrapper_className:'slideshow_eventi_wrapper',
                    arrow_left:{
                        id:'arrow_slideshow_eventi_left',
                        className:'arrow_slideshow_eventi left',
                        type:'left'
                    },
                    arrow_right:{
                        id:'arrow_slideshow_eventi_right',
                        className:'arrow_slideshow_eventi right',
                        type:'right'
                    },
                    slides:[
                        {
                            id:'slide_slideshow_homepage_0',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/0.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_1',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/1.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_2',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/2.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_3',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/3.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_4',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/4.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_5',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/5.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_6',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/6.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_7',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/7.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_8',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/8.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_9',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/9.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_10',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/10.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_11',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/11.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_12',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/12.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_13',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/13.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_14',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2016/14.jpg'
                        }
                    ]
                },
            },
            {
                text:{
                    id:'text_title_eventi_list',
                    className:'text_title_eventi_list',
                    content:'SALONE INTERNAZIONALE DEL MOBILE 2015',
                    type:'h2'
                },
                slideshow:{
                    id:'slideshow_eventi',
                    className:'slideshow_eventi',
                    wrapper_className:'slideshow_eventi_wrapper',
                    arrow_left:{
                        id:'arrow_slideshow_eventi_left',
                        className:'arrow_slideshow_eventi left',
                        type:'left'
                    },
                    arrow_right:{
                        id:'arrow_slideshow_eventi_right',
                        className:'arrow_slideshow_eventi right',
                        type:'right'
                    },
                    slides:[
                        {
                            id:'slide_slideshow_homepage_0',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/0.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_1',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/1.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_2',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/2.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_3',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/3.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_4',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/4.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_5',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/5.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_6',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/6.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_7',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/7.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_8',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/8.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_9',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/9.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_10',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/10.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_11',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/11.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_12',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/12.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_13',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/13.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_14',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2015/14.jpg'
                        }
                    ]
                },
            },
            {
                text:{
                    id:'text_title_eventi_list',
                    className:'text_title_eventi_list',
                    content:'SALONE INTERNAZIONALE DEL MOBILE 2011',
                    type:'h2'
                },
                slideshow:{
                    id:'slideshow_eventi',
                    className:'slideshow_eventi',
                    wrapper_className:'slideshow_eventi_wrapper',
                    arrow_left:{
                        id:'arrow_slideshow_eventi_left',
                        className:'arrow_slideshow_eventi left',
                        type:'left'
                    },
                    arrow_right:{
                        id:'arrow_slideshow_eventi_right',
                        className:'arrow_slideshow_eventi right',
                        type:'right'
                    },
                    slides:[
                        {
                            id:'slide_slideshow_homepage_0',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/0.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_1',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/1.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_2',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/2.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_3',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/3.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_4',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/4.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_5',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/5.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_6',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/6.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_7',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/7.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_8',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/8.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_9',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/9.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_10',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/10.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_11',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/11.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_12',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/12.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_13',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/13.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_14',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2011/14.jpg'
                        }
                    ]
                },
            },
            {
                text:{
                    id:'text_title_eventi_list',
                    className:'text_title_eventi_list',
                    content:'SALONE INTERNAZIONALE DEL MOBILE 2010',
                    type:'h2'
                },
                slideshow:{
                    id:'slideshow_eventi',
                    className:'slideshow_eventi',
                    wrapper_className:'slideshow_eventi_wrapper',
                    arrow_left:{
                        id:'arrow_slideshow_eventi_left',
                        className:'arrow_slideshow_eventi left',
                        type:'left'
                    },
                    arrow_right:{
                        id:'arrow_slideshow_eventi_right',
                        className:'arrow_slideshow_eventi right',
                        type:'right'
                    },
                    slides:[
                        {
                            id:'slide_slideshow_homepage_0',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/0.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_1',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/1.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_2',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/2.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_3',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/3.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_4',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/4.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_5',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/5.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_6',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/6.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_7',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/7.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_8',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/8.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_9',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/9.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_10',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/10.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_11',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/11.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_12',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/12.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_13',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/13.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_14',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2010/14.jpg'
                        }
                    ]
                },
            },
            {
                text:{
                    id:'text_title_eventi_list',
                    className:'text_title_eventi_list',
                    content:'SALONE INTERNAZIONALE DEL MOBILE 2009',
                    type:'h2'
                },
                slideshow:{
                    id:'slideshow_eventi',
                    className:'slideshow_eventi',
                    wrapper_className:'slideshow_eventi_wrapper',
                    arrow_left:{
                        id:'arrow_slideshow_eventi_left',
                        className:'arrow_slideshow_eventi left',
                        type:'left'
                    },
                    arrow_right:{
                        id:'arrow_slideshow_eventi_right',
                        className:'arrow_slideshow_eventi right',
                        type:'right'
                    },
                    slides:[
                        {
                            id:'slide_slideshow_homepage_0',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/0.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_1',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/1.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_2',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/2.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_3',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/3.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_4',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/4.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_5',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/5.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_6',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/6.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_7',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/7.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_8',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/8.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_9',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/9.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_10',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/10.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_11',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/11.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_12',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/12.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_13',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/13.jpg'
                        },
                        {
                            id:'slide_slideshow_homepage_14',
                            className:'slide_slideshow_eventi',
                            image_src:'/images/novita/eventi/salone-internazionale-del-mobile-2009/14.jpg'
                        }
                    ]
                },
            },
        ]
    };
}

function novitaProdotti(language){
    return{
        id:'novita_list',
        className:'novita_list',
        header:header(language),
        navigator:{
            id:'navigator',
            className:'navigator',
            path:[
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'HOME',
                    type:'p',
                    href:'/'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'NOVITÀ':'NEWS'}`,
                    type:'p',
                    href:'/novita'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'PRODOTTI':'PRODUCTS'}`,
                    type:'p',
                    href:'/novita/prodotti'
                }
            ]
        },
        novita:[
            {
                className:'novita_list_items',
                container_className:'text_novita_list_div',
                text:{
                    id:'text_novita_list',
                    className:'text_novita_list',
                    content:novita_database[0][language],
                    type:'p'
                },
                images:[
                    {
                        id:'image_novita_list',
                        className:'image_novita_list',
                        src:'/images/novita/prodotti/tavolo-london/0.jpg',
                        alt:'news_0'
                    }
                ]
            },
            {
                className:'novita_list_items',
                container_className:'text_novita_list_div',
                text:{
                    id:'text_novita_list',
                    className:'text_novita_list',
                    content:novita_database[1][language],
                    type:'p'
                },
                images:[
                    {
                        id:'image_novita_list',
                        className:'image_novita_list',
                        src:'/images/novita/prodotti/tavolo-alicante/0.jpg',
                        alt:'news_1'
                    }
                ]
            },
            {
                className:'novita_list_items',
                container_className:'text_novita_list_div',
                text:{
                    id:'text_novita_list',
                    className:'text_novita_list',
                    content:novita_database[2][language],
                    type:'p'
                },
                images:[
                    {
                        id:'image_novita_list',
                        className:'image_novita_list',
                        src:'/images/novita/prodotti/tavolo-bridge/0.jpg',
                        alt:'news_2'
                    }
                ]
            },
            {
                className:'novita_list_items',
                container_className:'text_novita_list_div',
                text:{
                    id:'text_novita_list',
                    className:'text_novita_list',
                    content:novita_database[3][language],
                    type:'p'
                },
                images:[
                    {
                        id:'image_novita_list',
                        className:'image_novita_list',
                        src:'/images/novita/prodotti/tavolo-allungabile-kinesis/0.jpg',
                        alt:'news_3'
                    }
                ]
            },
            {
                className:'novita_list_items',
                container_className:'text_novita_list_div',
                text:{
                    id:'text_novita_list',
                    className:'text_novita_list',
                    content:novita_database[4][language],
                    type:'p'
                },
                images:[
                    {
                        id:'image_novita_list',
                        className:'image_novita_list',
                        src:'/images/novita/prodotti/poltroncina-mia/0.jpg',
                        alt:'news_4'
                    },
                    {
                        id:'image_novita_list',
                        className:'image_novita_list',
                        src:'/images/novita/prodotti/poltroncina-mia/1.jpg',
                        alt:'news_4'
                    }
                ]
            },
            {
                className:'novita_list_items',
                container_className:'text_novita_list_div',
                text:{
                    id:'text_novita_list',
                    className:'text_novita_list',
                    content:novita_database[5][language],
                    type:'p'
                },
                images:[
                    {
                        id:'image_novita_list',
                        className:'image_novita_list',
                        src:'/images/novita/prodotti/tavolo-sintesi/0.jpg',
                        alt:'news_5'
                    }
                ]
            },
        ]
    };
}

function novitaDettaglio(language,novita){
    console.log(novita);
    let novita_item=novitaDatabase(language,novita,true);
    return{
        id:'novita_dettaglio',
        className:'novita_dettaglio',
        container_className:'text_novita_dettaglio_div',
        header:header(language),
        navigator:{
            id:'navigator',
            className:'navigator',
            path:[
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'HOME',
                    type:'p',
                    href:'/'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'NOVITÀ':'NEWS'}`,
                    type:'p',
                    href:'/novita'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'PRODOTTI':'PRODUCTS'}`,
                    type:'p',
                    href:'/novita/prodotti'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'\u2003>\u2003'+novita_item[language].toUpperCase(),
                    type:'p',
                    href:'/novita/prodotti/'+novita
                }
            ]
        },
        text:{
            id:'text_title_novita_dettaglio',
            className:'text_title_novita_dettaglio',
            content:novita_item[language],
            type:'h2'
        },
        images:novita_item.images
    };
}

function eventoDettaglio(language,evento){
    let evento_item=getEvento(evento);
    return{
        id:'evento_dettaglio',
        className:'evento_dettaglio',
        container_className:'text_title_eventi_dettaglio_div',
        header:header(language),
        navigator:{
            id:'navigator',
            className:'navigator',
            path:[
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'HOME',
                    type:'p',
                    href:'/'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'NOVITÀ':'NOVITÀ'}`,
                    type:'p',
                    href:'/novita'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'EVENTI':'EVENTS'}`,
                    type:'p',
                    href:'/novita/eventi'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'\u2003>\u2003'+evento_item[language].toUpperCase(),
                    type:'p',
                    href:'/novita/eventi/'+evento
                }
            ]
        },
        text:{
            id:'text_title_eventi_dettaglio',
            className:'text_title_eventi_dettaglio',
            content:evento_item[language],
            type:'h2'
        },
        slideshow:{
            id:'slideshow_eventi',
            className:'slideshow_eventi',
            wrapper_className:'slideshow_eventi_wrapper',
            arrow_left:{
                id:'arrow_slideshow_eventi_left',
                className:'arrow_slideshow_eventi left',
                type:'left'
            },
            arrow_right:{
                id:'arrow_slideshow_eventi_right',
                className:'arrow_slideshow_eventi right',
                type:'right'
            },
            slides:evento_item.slides
        },
    };
}

function press(language){
    return{
        id:'press',
        className:'press',
        container_className:'text_title_press_div',
        header:header(language),
        navigator:{
            id:'navigator',
            className:'navigator',
            path:[
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'HOME',
                    type:'p',
                    href:'/'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'\u2003>\u2003PRESS',
                    type:'p',
                    href:'/press'
                }
            ]
        },
        presses:[
            {
                text:{
                    id:'text_press',
                    className:'text_press',
                    content:'COSE DI CASA 2011',
                    type:'p'
                },
                images:[
                    {
                        id:'image_press',
                        className:'image_press',
                        src:'/images/novita/prodotti/cose-di-casa-2011/0.jpg',
                        alt:'news_0'
                    }
                ]
            },
            {
                text:{
                    id:'text_press',
                    className:'text_press',
                    content:'COSE DI CASA 2010',
                    type:'p'
                },
                images:[
                    {
                        id:'image_press',
                        className:'image_press',
                        src:'/images/novita/prodotti/cose-di-casa-2010/0.jpg',
                        alt:'news_1'
                    }
                ]
            },
            {
                text:{
                    id:'text_press',
                    className:'text_press',
                    content:'GAGU MAGAZINE - KOREA',
                    type:'p'
                },
                images:[
                    {
                        id:'image_press',
                        className:'image_press',
                        src:'/images/novita/prodotti/gagu-magazine-korea/0.jpg',
                        alt:'news_2'
                    },
                    {
                        id:'image_press',
                        className:'image_press',
                        src:'/images/novita/prodotti/gagu-magazine-korea/1.jpg',
                        alt:'news_2'
                    }
                ]
            }
        ]
    };
}

function downloadCataloghi(language){
    return{
        id:'download_cataloghi',
        className:'download_cataloghi',
        header:header(language),
        navigator:{
            id:'navigator',
            className:'navigator',
            path:[
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'HOME',
                    type:'p',
                    href:'/'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'\u2003>\u2003DOWNLOAD',
                    type:'p',
                    href:'/download'
                }
            ]
        },
        text_title:{
            id:'text_title_download',
            className:'text_title_download',
            content:form.download.title[language],
            type:'h2'
        },
        text_description:{
            id:'text_description_download',
            className:'text_description_download',
            content:form.download.description[language],
            type:'p'
        },
        form:{
            className:'form_download_cataloghi',
            fields:[
                {
                    category:'input',
                    input:{
                        id:'input_nome_download_cataloghi',
                        type:'text',
                        placeholder:form.download.inputs[0][language],
                        className:'input_download_cataloghi',
                        required:true
                    }
                },
                {
                    category:'input',
                    input:{
                        id:'input_cognome_download_cataloghi',
                        type:'text',
                        placeholder:form.download.inputs[1][language],
                        className:'input_download_cataloghi',
                        required:true
                    }
                },
                {
                    category:'input',
                    input:{
                        id:'input_email_download_cataloghi',
                        type:'email',
                        placeholder:form.download.inputs[2][language],
                        className:'input_download_cataloghi',
                        required:true
                    }
                },
                {
                    category:'submit',
                    button:{
                        id:'submit_download_cataloghi',
                        value:'Invia',
                        onClick:function(e){
                            e.preventDefault();
                            if(document.getElementById('input_nome_download_cataloghi').value===''){
                                document.getElementById('input_nome_download_cataloghi').focus();
                            }else if(document.getElementById('input_cognome_download_cataloghi').value===''){
                                document.getElementById('input_cognome_download_cataloghi').focus();
                            }else if(document.getElementById('input_email_download_cataloghi').value===''){
                                document.getElementById('input_email_download_cataloghi').focus();
                            }else{
                                Axios.get('/server.php',{
                                    params:{
                                        type:'cataloghi',
                                        nome:document.getElementById('input_nome_download_cataloghi').value,
                                        cognome:document.getElementById('input_cognome_download_cataloghi').value,
                                        email:document.getElementById('input_email_download_cataloghi').value,
                                    }
                                })
                                    .then((response)=>{
                                        if(response.status===200){
                                            window.location.href='/download/cataloghi';
                                        }
                                    });
                            }
                        },
                        className:'input_download_cataloghi',

                    }
                }
            ]
        }
    };
}

function contattiInfo(language){
    return{
        id:'contatti_info',
        className:'contatti_info',
        container_className:'contatti_info_div',
        left_column_className:'column_contatti left',
        right_column_className:'column_contatti right',
        center_column_className:'column_contatti center',
        header:header(language),
        navigator:{
            id:'navigator',
            className:'navigator',
            path:[
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'HOME',
                    type:'p',
                    href:'/'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'CONTATTI':'CONTACTS'}`,
                    type:'p',
                    href:'/contatti'
                }
            ]
        },
        text:[
            {
                id:'text_contatti',
                className:'text_contatti',
                content:contatti[0][language],
                type:'p'
            },
            {
                id:'text_contatti',
                className:'text_contatti',
                content:contatti[1][language],
                type:'p'
            },
            {
                id:'text_contatti',
                className:'text_contatti',
                content:contatti[2][language],
                type:'p'
            },
            {
                id:'text_contatti',
                className:'text_contatti',
                content:contatti[3][language],
                type:'p'
            },
            {
                id:'text_contatti',
                className:'text_contatti',
                content:contatti[4][language],
                type:'p'
            },
            {
                id:'text_contatti',
                className:'text_contatti',
                content:contatti[5][language],
                type:'p',
                href:`mailto:${contatti[5][language]}`
            },
            {
                id:'text_contatti',
                className:'text_contatti',
                content:contatti[6][language],
                type:'p',
                href:`mailto:${contatti[6][language]}`
            },
            {
                id:'text_contatti',
                className:'text_contatti',
                content:contatti[7][language],
                type:'p',
                href:`mailto:${contatti[7][language]}`
            },
            {
                id:'text_contatti',
                className:'text_contatti',
                content:contatti[8][language],
                type:'p',
                href:`mailto:${contatti[8][language]}`
            },
            {
                id:'text_contatti',
                className:'text_contatti',
                content:contatti[9][language],
                type:'p',
                href:`mailto:${contatti[9][language]}`
            },
            {
                id:'text_contatti',
                className:'text_contatti',
                content:contatti[10][language],
                type:'p',
                href:'/contatti/richiesta-contatti'
            },
            {
                id:'text_contatti',
                className:'text_contatti',
                content:contatti[11][language],
                type:'p'
            },
        ]
    };
}

function reteVendita(language){
    return{
        id:'rete_vendita',
        className:'rete_vendita',
        header:header(language),
        navigator:{
            id:'navigator',
            className:'navigator',
            path:[
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'HOME',
                    type:'p',
                    href:'/'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'CONTATTI':'CONTACTS'}`,
                    type:'p',
                    href:'/contatti'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'RETE VENDITA':'SALES NETWORK'}`,
                    type:'p',
                    href:'/contatti/rete-vendita'
                }
            ]
        },
        text:{
            id:'text_rete_vendita',
            className:'text_rete_vendita',
            content:rete_vendita[language],
            type:'h2'
        }
    };
}

function richiestaContatti(language){
    return{
        id:'richiesta_contatti',
        className:'richiesta_contatti',
        header:header(language),
        navigator:{
            id:'navigator',
            className:'navigator',
            path:[
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'HOME',
                    type:'p',
                    href:'/'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'CONTATTI':'CONTACTS'}`,
                    type:'p',
                    href:'/contatti'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'RICHIESTA CONTATTI':'REQUEST INFORMATIONS'}`,
                    type:'p',
                    href:'/contatti/richiesta-contatti'
                }
            ]
        },
        form:{
            className:'form_richiesta_contatti',
            fields:[
                {
                    category:'input',
                    input:{
                        id:'input_nome_richiesta_contatti',
                        type:'text',
                        placeholder:form.contatti.inputs[0][language],
                        className:'input_richiesta_contatti',

                    }
                },
                {
                    category:'input',
                    input:{
                        id:'input_cognome_richiesta_contatti',
                        type:'text',
                        placeholder:form.contatti.inputs[1][language],
                        className:'input_richiesta_contatti',

                    }
                },
                {
                    category:'input',
                    input:{
                        id:'input_societa_richiesta_contatti',
                        type:'text',
                        placeholder:form.contatti.inputs[2][language],
                        className:'input_richiesta_contatti',

                    }
                },
                {
                    category:'selector',
                    selector:{
                        id:'input_tipologia_richiesta_contatti',
                        className:'selector_form_div',
                        text:{
                            id:'text_placeholder_selector_form',
                            className:'text_placeholder_selector_form',
                            content:form.contatti.inputs[3][language],
                            type:'p'
                        },
                        selector_className:'selector_form',
                        options:[
                            {
                                value:form.contatti.inputs[3].options[0][language],
                                content:form.contatti.inputs[3].options[0][language]
                            },
                            {
                                value:form.contatti.inputs[3].options[1][language],
                                content:form.contatti.inputs[3].options[1][language]
                            },
                            {
                                value:form.contatti.inputs[3].options[2][language],
                                content:form.contatti.inputs[3].options[2][language]
                            },
                            {
                                value:form.contatti.inputs[3].options[3][language],
                                content:form.contatti.inputs[3].options[3][language]
                            },
                            {
                                value:form.contatti.inputs[3].options[4][language],
                                content:form.contatti.inputs[3].options[4][language]
                            }
                        ]
                    }
                },
                {
                    category:'input',
                    input:{
                        id:'input_email_richiesta_contatti',
                        type:'text',
                        placeholder:form.contatti.inputs[4][language],
                        className:'input_richiesta_contatti',

                    }
                },
                {
                    category:'input',
                    input:{
                        id:'input_telefono_richiesta_contatti',
                        type:'text',
                        placeholder:form.contatti.inputs[5][language],
                        className:'input_richiesta_contatti',

                    }
                },
                {
                    category:'input',
                    input:{
                        id:'input_messaggio_richiesta_contatti',
                        type:'text',
                        placeholder:form.contatti.inputs[6][language],
                        className:'input_richiesta_contatti',
                    }
                },
                {
                    category:'submit',
                    button:{
                        id:'submit_richiesta_contatti',
                        value:'Invia',
                        onClick:function(e){
                            e.preventDefault();
                            if(document.getElementById('input_nome_richiesta_contatti').value===''){
                                document.getElementById('input_nome_richiesta_contatti').focus();
                            }else if(document.getElementById('input_cognome_richiesta_contatti').value===''){
                                document.getElementById('input_cognome_richiesta_contatti').focus();
                            }else if(document.getElementById('input_email_richiesta_contatti').value===''){
                                document.getElementById('input_email_richiesta_contatti').focus();
                            }else if(document.getElementById('input_messaggio_richiesta_contatti').value===''){
                                document.getElementById('input_messaggio_richiesta_contatti').focus();
                            }else{
                                Axios.get('/server.php',{
                                    params:{
                                        type:'contatti',
                                        nome:document.getElementById('input_nome_richiesta_contatti').value,
                                        cognome:document.getElementById('input_cognome_richiesta_contatti').value,
                                        societa:document.getElementById('input_societa_richiesta_contatti').value,
                                        tipologia:document.getElementById('input_tipologia_richiesta_contatti').value,
                                        email:document.getElementById('input_email_richiesta_contatti').value,
                                        telefono:document.getElementById('input_telefono_richiesta_contatti').value,
                                        messaggio:document.getElementById('input_messaggio_richiesta_contatti').value
                                    }
                                })
                                    .then((response)=>{
                                        if(response.status===200){
                                            window.location.href='/contatti/richiesta-contatti/success';
                                        }
                                    });
                            }
                        },
                        className:'input_richiesta_contatti',
                    }
                }
            ]
        }
    };
}

function download(language){
    return{
        id:'cataloghi',
        className:'cataloghi',
        header:header(language),
        navigator:{
            id:'navigator',
            className:'navigator',
            path:[
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:'HOME',
                    type:'p',
                    href:'/'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003DOWNLOAD`,
                    type:'p',
                    href:'/download'
                },
                {
                    id:'text_navigator',
                    className:'text_navigator',
                    content:`\u2003>\u2003${language==='it'?'CATALOGHI':'CATALOGS'}`,
                    type:'p'
                }
            ]
        },
        text:[
            {
                id:'catalogo_0',
                className:'catalogo',
                content:language==='it'?'Download catalogo generale':'Download generic catalog',
                type:'p',
                download:`${host}/cataloghi/catalogo-generale-linfadesign.pdf`,
                filename:'catalogo-generale-linfadesign.pdf'
            },
            {
                id:'catalogo_1',
                className:'catalogo',
                content:language==='it'?'Download catalogo':'Download catalog',
                type:'p',
                download:`${host}/cataloghi/catalogo-linfadesign.pdf`,
                filename:'catalogo-linfadesign.pdf'
            }
        ]
    };
}

function richiestaContattiSuccess(language){
    return{
        id:'richiesta_contatti_success',
        className:'richiesta_contatti_success',
        header:header(language),
        text:{
            id:'text_richiesta_contatti_success',
            className:'text_richiesta_contatti_success',
            content:language==='it'?'Richiesta inviata con successo':'Request sent successfully',
            type:'h2'
        }
    };
}

function header(language){
    return{
        id:'header',
        className:'header',
        selector:{
            id:'selector_header',
            className:'selector_header_div',
            text:{
                id:'text_placeholder_selector_header',
                className:'text_placeholder_selector_header',
                content:placeholder_selector_header[language],
                type:'p'
            },
            selector_className:'selector_header',
            options:[
                {
                    value:language==='it'?'it':'en',
                    content:language==='it'?'Italiano':'English'
                },
                {
                    value:language==='it'?'en':'it',
                    content:language==='it'?'English':'Italiano'
                }
            ]
        },
        image:{
            id:'image_logo',
            className:'image_logo',
            src:'/logo.png',
            alt:alt_image_logo[language],
            href:'/'
        },
        menu:{
            id:'menu_header',
            className:'menu_nav_header',
            menu_className:'menu_header',
            submenu_title_className:'menu_header_submenu_title',
            submenu_className:'menu_header_submenu',
            submenu_item_className:'menu_header_submenu_item',
            options:[
                [
                    {
                        id:'menu_header_option_prodotti',
                        className:'menu_header_option',
                        content:menu_header_option.prodotti[language],
                        href:'/prodotti',
                        type:'p'
                    },
                    {
                        id:'menu_header_option_prodotti_tavoli',
                        className:'menu_header_option submenu',
                        content:menu_header_option.prodotti.tavoli[language],
                        href:'/prodotti/tavoli',
                        type:'p'
                    },
                    {
                        id:'menu_header_option_prodotti_sedie_sgabelli',
                        className:'menu_header_option submenu',
                        content:menu_header_option.prodotti.sedie_sgabelli[language],
                        href:'/prodotti/sedie-sgabelli',
                        type:'p'
                    },
                    {
                        id:'menu_header_option_prodotti_credenze',
                        className:'menu_header_option submenu',
                        content:menu_header_option.prodotti.credenze[language],
                        href:'/prodotti/credenze',
                        type:'p'
                    },
                    {
                        id:'menu_header_option_prodotti_poltrone_poufs',
                        className:'menu_header_option submenu',
                        content:menu_header_option.prodotti.poltrone_poufs[language],
                        href:'/prodotti/poltrone-poufs',
                        type:'p'
                    },
                    {
                        id:'menu_header_option_prodotti_tavolini',
                        className:'menu_header_option submenu',
                        content:menu_header_option.prodotti.tavolini[language],
                        href:'/prodotti/tavolini',
                        type:'p'
                    },
                    {
                        id:'menu_header_option_prodotti_librerie',
                        className:'menu_header_option submenu',
                        content:menu_header_option.prodotti.librerie[language],
                        href:'/prodotti/librerie',
                        type:'p'
                    },
                    {
                        id:'menu_header_option_prodotti_mensole',
                        className:'menu_header_option submenu',
                        content:menu_header_option.prodotti.mensole[language],
                        href:'/prodotti/mensole',
                        type:'p'
                    },
                    {
                        id:'menu_header_option_prodotti_specchiere',
                        className:'menu_header_option submenu',
                        content:menu_header_option.prodotti.specchiere[language],
                        href:'/prodotti/specchiere',
                        type:'p'
                    },
                    {
                        id:'menu_header_option_prodotti_appendiabiti',
                        className:'menu_header_option submenu',
                        content:menu_header_option.prodotti.appendiabiti[language],
                        href:'/prodotti/appendiabiti',
                        type:'p'
                    },
                    {
                        id:'menu_header_option_prodotti_varie',
                        className:'menu_header_option submenu',
                        content:menu_header_option.prodotti.varie[language],
                        href:'/prodotti/varie',
                        type:'p'
                    }
                ],
                [
                    {
                        id:'menu_header_option_azienda',
                        className:'menu_header_option',
                        content:menu_header_option.azienda[language],
                        href:'/azienda',
                        type:'p'
                    }
                ],
                [
                    {
                        id:'menu_header_option_novita',
                        className:'menu_header_option',
                        content:menu_header_option.novita[language],
                        href:'/novita',
                        type:'p'
                    },
                    {
                        id:'menu_header_option_novita_eventi',
                        className:'menu_header_option submenu',
                        content:menu_header_option.novita.eventi[language],
                        href:'/novita/eventi',
                        type:'p'
                    },
                    {
                        id:'menu_header_option_novita_prodotti',
                        className:'menu_header_option submenu',
                        content:menu_header_option.novita.prodotti[language],
                        href:'/novita/prodotti',
                        type:'p'
                    }
                ],
                [
                    {
                        id:'menu_header_option_press',
                        className:'menu_header_option',
                        content:menu_header_option.press[language],
                        href:'/press',
                        type:'p'
                    }
                ],
                [
                    {
                        id:'menu_header_option_download',
                        className:'menu_header_option',
                        content:menu_header_option.download[language],
                        href:'/download',
                        type:'p'
                    }
                ],
                [
                    {
                        id:'menu_header_option_contatti',
                        className:'menu_header_option',
                        content:menu_header_option.contatti[language],
                        href:'/contatti',
                        type:'p'
                    },
                    {
                        id:'menu_header_option_contatti_rete_vendita',
                        className:'menu_header_option submenu',
                        content:menu_header_option.contatti.rete_vendita[language],
                        href:'/contatti/rete-vendita',
                        type:'p'
                    },
                    {
                        id:'menu_header_option_contatti_richiedi_informazioni',
                        className:'menu_header_option submenu',
                        content:menu_header_option.contatti.richiedi_informazioni[language],
                        href:'/contatti/richiesta-contatti',
                        type:'p'
                    }
                ]
            ]
        }
    };
}

function prodotti(categoria,language){
    switch(categoria){
        case 'tavoli':{
            return[
                {
                    id:'prodotto_0',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_0',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/superstar-linfadesign/0.jpg`,
                        href:`/prodotti/${categoria}/superstar-linfadesign`,
                        alt:prodotto[categoria][0].title[language]
                    },
                    text:{
                        id:'text_prodotto_0',
                        className:'text_prodotto',
                        content:prodotto[categoria][0].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_1',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_1',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis-t-linfa-design/0.jpg`,
                        href:`/prodotti/${categoria}/kinesis-t-linfa-design`,
                        alt:prodotto[categoria][1].title[language]
                    },
                    text:{
                        id:'text_prodotto_1',
                        className:'text_prodotto',
                        content:prodotto[categoria][1].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis-ext-linfa-design/0.jpg`,
                        href:`/prodotti/${categoria}/kinesis-ext-linfa-design`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis-ext-linfa-design/0.jpg`,
                        href:`/prodotti/${categoria}/kinesis-ext-linfa-design`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                },
                {
                    id:'prodotto_2',
                    className:'prodotto',
                    container_className:'prodotto_text_container',
                    image:{
                        id:'image_prodotto_2',
                        className:'image_prodotto',
                        src:`/images/categorie/${categoria}/kinesis_tavolo_allungabile/0.png`,
                        href:`/prodotti/${categoria}/kinesis-tavolo-allungabile`,
                        alt:prodotto[categoria][2].title[language]
                    },
                    text:{
                        id:'text_prodotto_2',
                        className:'text_prodotto',
                        content:prodotto[categoria][2].title[language],
                        type:'p'
                    }
                }
            ];
        }
        default:{return null;}
    }
}

const placeholder_selector_header={
    it:'LA TUA LINGUA',
    en:'YOUR LANGUAGE'
};

const alt_image_logo={
    it:'LinfaDesign logo',
    en:'LinfaDesign logo'
};

const menu_header_option={
    prodotti:{
        it:'Prodotti',
        en:'Products',
        tavoli:{
            it:'Tavoli',
            en:'Tables'
        },
        sedie_sgabelli:{
            it:'Sedie - Sgabelli',
            en:'Chairs - Stools'
        },
        credenze:{
            it:'Credenze',
            en:'Sideboards'
        },
        poltrone_poufs:{
            it:'Poltrone - Poufs',
            en:'Armchairs - Poufs'
        },
        tavolini:{
            it:'Tavolini',
            en:'Side Tables'
        },
        librerie:{
            it:'Librerie',
            en:'Libraries'
        },
        mensole:{
            it:'Mensole',
            en:'Shelves'
        },
        specchiere:{
            it:'Specchiere',
            en:'Mirrors'
        },
        appendiabiti:{
            it:'Appendiabiti',
            en:'Clothes hanger'
        },
        varie:{
            it:'Varie',
            en:'Various'
        }
    },
    azienda:{
        it:'Azienda',
        en:'Company'
    },
    novita:{
        it:'Novità',
        en:'News',
        eventi:{
            it:'Eventi',
            en:'Events'
        },
        prodotti:{
            it:'Prodotti',
            en:'Products'
        }
    },
    press:{
        it:'Press',
        en:'Press'
    },
    download:{
        it:'Download',
        en:'Download'
    },
    contatti:{
        it:'Contatti',
        en:'Contacts',
        rete_vendita:{
            it:'Rete Vendita',
            en:'Sales Network'
        },
        richiedi_informazioni:{
            it:'Richiedi informazioni',
            en:'Request Informations'
        }
    }
};

const alt_homepage_left_image={
    it:'Press',
    en:'Press'
};

const alt_homepage_right_image={
    it:'Rete vendita',
    en:'Find a store'
};

const categorie=[
    {
        it:'Tavoli',
        en:'Tables',
        src:'tavoli'
    },
    {
        it:'Sedie - Sgabelli',
        en:'Chairs - Stool',
        src:'sedie-sgabelli'
    },
    {
        it:'Credenze',
        en:'Sideboard',
        src:'credenze'
    },
    {
        it:'Poltrone - Poufs',
        en:'Armchair - Poufs',
        src:'poltrone-poufs'
    },
    {
        it:'Tavolini',
        en:'Coffee Table',
        src:'tavolini'
    },
    {
        it:'Librerie',
        en:'Bookcase',
        src:'librerie'
    },
    {
        it:'Mensole',
        en:'Shelves',
        src:'mensole'
    },
    {
        it:'Specchiere',
        en:'Mirrors',
        src:'specchiere'
    },
    {
        it:'Appendiabiti',
        en:'Clothes Stands',
        src:'appendiabiti'
    },
    {
        it:'Varie',
        en:'Various',
        src:'varie'
    }
];

const prodotto={
    'tavoli':[
        {
            title:{
                it:'SUPERSTAR - TAVOLO',
                en:'SUPERSTAR - TABLE'
            },
            href:'superstar-linfadesign',
            descrizione:{
                it:'Tavolo da pranzo con basamento rovere o noce canaletto. Disponibile con piano cristallo temperato diametro 140cm spessore 10mm oppure diametro 160cm spessore 12mm.\n\n\n\nTA-552\n\n\nAltezza: 74 cm.\n\n\nDiametro: 140 cm.\n\n\n\nTA-553\n\n\nAltezza: 74 cm.\n\n\nDiametro: 160 cm.',
                en:'Dining-table with base in oak or american walnut. Available with temperal glass top.\n\nTA-552\nHeight: 74 cm.\nDiameter: 140 cm.\n\nTA-553\nHeight: 74 cm.\nDiameter: 160 cm.'
            },
            n_images:3
        },
        {
            title:{
                it:'KÌNESIS - TAVOLO',
                en:'KÌNESIS - TABLE'
            },
            href:'kinesis-t-linfa-design',
            descrizione:{
                it:'Tavolo da pranzo in legno massello di rovere o noce canaletto. Disponibile anche in rovere laccato opaco poro aperto e nella versione TA-543(240x100x75H), inviare richiesta.\n\n\n\nTA-541:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 170 cm\n\n\nLarghezza: 90 cm\n\n\n\nTA-542:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 200 cm\n\n\nLarghezza: 90 cm\n\n\n\nTA-544:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 130 cm\n\n\nLarghezza: 130 cm',
                en:'Dining room table in solid oak or american walnut. Available mat lacquered oak open pore and in the version TA-543(240x100x75H), send request.\n\nTA-541:\nHeight: 75 cm\nLength: 170 cm\nWidth: 90 cm\n\nTA-542:\nHeight: 75 cm\nLength: 200 cm\nWidth: 90 cm\n\nTA-544:\nHeight: 75 cm\nLength: 130 cm\nWidth: 130 cm'
            },
            n_images:6
        },
        {
            title:{
                it:'KÌNESIS - TAVOLO ALLUNGABILE',
                en:'KÌNESIS - EXTENDIBLE TABLE'
            },
            href:'kinesis-ext-linfa-design',
            descrizione:{
                it:'Tavolo allungabile da pranzo in rovere o noce canaletto. Disponibile in rovere laccato poro aperto.\n\n\n\nTA-545:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 170(+50+50) cm\n\n\nLarghezza: 90 cm\n\n\n\nTA-546:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 150(+50) cm\n\n\nLarghezza: 90 cm',
                en:'Extendible dining room table in oak or american walnut. Available lacquered oak open pore, send request.\n\nTA-545:\nHeight: 75 cm\nLength: 170(+50+50) cm\nWidth: 90 cm\n\nTA-546:\nHeight: 75 cm\nLength: 150(+50) cm\nWidth: 90 cm'
            },
            n_images:6
        },
        {
            title:{
                it:'PIGRECO - TAVOLO ALLUNGABILE',
                en:'PIGRECO - EXTENDIBLE TABLE'
            },
            href:'pigreco-ext-linfa-design',
            descrizione:{
                it:'Tavolo allungabile da pranzo in rovere o noce canaletto. Le allunghe laterali sono ripiegate sotto il piano del tavolo. Piano in kerlite\n\n\n\n\nTA-554:\n\nAltezza: 74 cm\n\nLunghezza: 140(+50) cm\n\nLarghezza: 86 cm\n\n\n\nTA-555:\n\nAltezza: 74 cm\n\nLunghezza: 160(+50) cm\n\nLarghezza: 86 cm',
                en:'Extendible dining room table in oak or american walnut. The side extensions are folded under the table top. Top kerlite.\n\nTA-554:\nHeight: 74 cm\nLength: 140(+50) cm\nWidth: 86 cm\n\nTA-555:\nHeight: 74 cm\nLength: 160(+50) cm\nWidth: 86 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'TECNO - TAVOLO',
                en:'TECNO - TABLE'
            },
            href:'tecno-linfa-design',
            descrizione:{
                it:'Tavolo da pranzo fisso in rovere o noce canaletto.\n\n\n\nTA-509:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 120 cm\n\n\nLarghezza: 120 cm\n\n\n\nTA-516:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 180 cm\n\n\nLarghezza: 90 cm\n\n\n\nTA-518:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 200 cm\n\n\nLarghezza: 90 cm\n\n\n\nTA-520:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 220 cm\n\n\nLarghezza: 90 cm',
                en:'Fixed dining room table in oak or american walnut structure.\n\nTA-509:\nHeight: 75 cm\nLength: 120 cm\nWidth: 120 cm\n\nTA-516:\nHeight: 75 cm\nLength: 180 cm\nWidth: 90 cm\n\nTA-518:\nHeight: 75 cm\nLength: 200 cm\nWidth: 90 cm\n\nTA-520:\nHeight: 75 cm\nLength: 220 cm\nWidth: 90 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'ALICANTE - TAVOLO CONSOLLE',
                en:'ALICANTE - CONSOLLE TABLE'
            },
            href:'alicante-consolle-linfa-design',
            descrizione:{
                it:'Consolle Alicante Linfa Design: consolle in massello di rovere o noce canaletto. Disponibile anche nelle finture laccate opache.\n\n\n\nTA-563:\n\n\nAltezza: 74 cm\n\n\nLunghezza: 130 cm\n\n\nProfondità: 45 cm',
                en:'Alicante Console Linfa Design Console: Fixed console in solid oak or american walnut. Also available in matt lacquered finishes.\n\nTA-563:\nHeight: 74 cm\nLength: 130 cm\nWidth: 45 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'ALICANTE - TAVOLO',
                en:'ALICANTE - TABLE'
            },
            href:'linfa-design-alicante',
            descrizione:{
                it:'Tavolo Alicante Linfa Design: tavolo fisso in massello di rovere o noce canaletto, disponibile in varie finiture. Prodotto di alta qualità. Disponibile anche laccato in varie finiture.\n\n\n\nDimensioni:\n\n\nAltezza 74 cm\n\n\nLunghezza 180 cm\n\n\nProfondità 90 cm',
                en:'Alicante Linfa Design Table: fixed table in solid oak or american walnut structure, available in several finishes. High quality product. Also available in matt lacquered finishes.\n\nDimensions:\nHeight 74 cm\nLength 180 cm\nDepth 90 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'BRIDGE - TAVOLO CONSOLLE',
                en:'BRIDGE - CONSOLLE TABLE'
            },
            href:'linfa-design-bridge',
            descrizione:{
                it:'Consolle Bridge Linfa Design: Consolle fissa in legno impiallacciato rovere o noce canaletto, disponibile in varie finiture. Disponibile anche in finiture laccate opaco.\n\n\n\nDimensioni:\n\n\nAltezza 74 cm\n\n\nLarghezza 120 cm\n\n\nProfondità 45 cm',
                en:'Bridge Linfa Design Console: Fixed console in solid oak or american walnut, available in several finishes. Also available in matt lacquered finishes.\n\nDimensions:\nHeight 74 cm\nWidth 120 cm\nDepth 45 cm'
            },
            n_images:2
        },
        {
            title:{
                it:'LONDON - TAVOLO',
                en:'LONDON - TABLE'
            },
            href:'linfa-design-london',
            descrizione:{
                it:'Tavolo London Linfa Design: tavolo da pranzo fisso in massello di noce canaletto o rovere, disponibile in varie finiture. Disponibile anche in altre misure e con il piano in marmo o vetro.\n\n\n\nTavolo 567:\n\n\nAltezza 74 cm\n\n\nLunghezza 210 cm\n\n\nProfondità 105 cm\n\n\n\nTavolo 569:\n\n\nAltezza 74 cm\n\n\nLunghezza 180 cm\n\n\nProfondità 100 cm',
                en:'London Linfa Design Table: fixed dining table in solid american walnut or oak structure, available in several finishes. Also available in other measures and with marble or glass top.\n\nTable 567:\nHeight 74 cm\nLength 210 cm\nDepth 105 cm\n\nTable 569:\nHeight 74 cm\nLength 180 cm\nDepth 100 cm'
            },
            n_images:8
        },
        {
            title:{
                it:'ALICANTE - CONSOLLE ALLUNGABILE',
                en:'ALICANTE - EXTENDIBLE CONSOLLE'
            },
            href:'linfa-design-alicante-ec',
            descrizione:{
                it:'Consolle Alicante EC Linfa Design: consolle allungabile in massello di rovere o noce canaletto. Disponibile anche nelle finiture laccate opache.\n\n\n\nDimensioni:\n\n\nAltezza 74 cm\n\n\nLunghezza Consolle Chiusa 120 cm - Consolle Aperta 198 cm\n\n\nProfondità Consolle Chiusa 48 cm - Consolle Aperta 120 cm',
                en:'Alicante EC Linfa Design Console: extendable console in solid oak or american walnut structure. Also available in matt lacquered finishes.\n\nDimensions:\nHeight 74 cm\nLength Closed Console 120 cm - Open Console 198 cm\nDepth Closed Console 48 cm - Open Console 120 cm'
            },
            n_images:2
        },
        {
            title:{
                it:'TIMAVO - TAVOLO',
                en:'TIMAVO - TABLE'
            },
            href:'timavo-linfa-design',
            descrizione:{
                it:'Tavolo da pranzo in struttura di legno massello di rovere. Disponibile anche laccato opaco o lucido e in varie dimensioni. Disponibile anche tavolo TA-503(240x100xH76), inviare richiesta: info@sediedesign.it\n\n\n\nTA-522:\n\n\nAltezza: 76 cm\n\n\nLunghezza: 170 cm\n\n\nLarghezza: 90 cm\n\n\n\nTA-502:\n\n\nAltezza: 76 cm\n\n\nLunghezza: 200 cm\n\n\nLarghezza: 90 cm\n\n\n\nTA-504:\n\n\nAltezza: 76 cm\n\n\nLunghezza: 130 cm\n\n\nLarghezza: 130 cm',
                en:'Dining room table in solid oak structure. Available even mat or glossy lacquered and in various sizes. Also available Table TA-503(240x100xH76), send request.\n\nTA-522:\nHeight: 76 cm\nLength: 170 cm\nWidth: 90 cm\n\nTA-502:\nHeight: 76 cm\nLength: 200 cm\nWidth: 90 cm\n\nTA-504:\nHeight: 76 cm\nLength: 130 cm\nWidth: 130 cm'
            },
            n_images:5
        },
        {
            title:{
                it:'PARENTESI - TAVOLO ALLUNGABILE',
                en:'PARENTESI - EXTENDIBLE TABLE'
            },
            href:'parentesi-linfa-design',
            descrizione:{
                it:'Tavolo da pranzo allungabile in legno di rovere o noce canaletto. Disponibile laccato opaco. L\'allunga centrale e? ripiegata su se stessa sotto il piano.\n\n\n\nTA-505:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 180(+50) cm\n\n\nLarghezza: 90 cm\n\n\n\nTA-506:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 130(+50) cm\n\n\nLarghezza: 130 cm',
                en:'Extendible dining room table in oak or american walnut. Available mat lacquered. The central extension is folded under the top.\n\nTA-505:\nHeight: 75 cm\nLength: 180(+50) cm\nWidth: 90 cm\n\nTA-506:\nHeight: 75 cm\nLength: 130(+50) cm\nWidth: 130 cm'
            },
            n_images:4
        },
        {
            title:{
                it:'MORGAN - TAVOLO ALLUNGABILE',
                en:'MORGAN - EXTENDIBLE TABLE'
            },
            href:'morgan-linfa-design',
            descrizione:{
                it:'Tavolo da pranzo allungabile con piano in legno di rovere o noce canaletto. Le allunghe laterali sono ripiegate su se stesse sotto il piano. Disponibile anche laccato lucido.\n\n\n\nTA-531:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 146(+60) cm\n\n\nLarghezza: 90 cm\n\n\n\nTA-532:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 176(+45+45) cm\n\n\nLarghezza: 90 cm',
                en:'Extendible dining room table in oak or american walnut. The side extensions are folded under the top. Also available in glossy lacquered, send request.\n\nTA-531:\nHeight: 75 cm\nLength: 146(+60) cm\nWidth: 90 cm\n\nTA-532:\nHeight: 75 cm\nLength: 176(+45+45) cm\nWidth: 90 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'BANC - TAVOLO ALLUNGABILE',
                en:'BANC - EXTENDIBLE TABLE'
            },
            href:'banc-linfa-design',
            descrizione:{
                it:'Tavolo da sala da pranzo allungabile in massello di frassino con piano in vetro temperato. Finitura naturale. Estensione di impiallacciatura di legno di frassino.\n\n\n\nAltezza: 76 cm\n\n\nLunghezza: 180(+59) cm\n\n\nProfondità: 90 cm',
                en:'Dining room extendable table in solid ashwood with top in tempered glass. A natural finish. Extension of wood ash veneer.\n\nHeight: 76 cm\nLength: 180(+59) cm\nDepth: 90 cm'
            },
            n_images:8
        },
        {
            title:{
                it:'SINTESI - TAVOLO',
                en:'SINTESI - TABLE'
            },
            href:'sintesi-linfa-design',
            descrizione:{
                it:'Sala da pranzo tavolo fisso. Disponibile in massello di noce americano o rovere, in diverse dimensioni e colori. Disponibile anche nella versione TA-549(240x100x74H) e in rovere laccato poro aperto.\n\n\n\nTA-548:\n\n\nAltezza: 74 cm\n\n\nLunghezza: 200 cm\n\n\nLarghezza: 90 cm \n\n\n\nTA-551:\n\n\nAltezza: 74 cm\n\n\nLunghezza: 130 cm\n\n\nLarghezza: 130 cm\n\n\n\nTA-550\n\n\nAltezza: 74 cm\n\n\nLunghezza: 170 cm\n\n\nLarghezza: 90 cm',
                en:'Dining room table fix. Available in solid American walnut or oak, in different size and colour. Also available in version TA-549(240x100x74H) and in open pore lacquered oak, send request.\n\nTA-548:\nHeight: 74 cm\nLength: 200 cm\nWidth: 90 cm \n\nTA-551:\nHeight: 74 cm\nLength: 130 cm\nWidth: 130 cm\n\nTA-550\nHeight: 74 cm\nLength: 170 cm\nWidth: 90 cm'
            },
            n_images:5
        },
        {
            title:{
                it:'AGARICO - TAVOLO',
                en:'AGARICO - TABLE'
            },
            href:'agarico-linfa-design',
            descrizione:{
                it:'Tavolo da pranzo con base in multistrato curvato in rovere o noce canaletto. Disponibile laccato opaco. La base appoggia su una piastra in acciaio cromato o satinato.\n\n\n\nTA-534:\n\n\nAltezza: 75 cm\n\n\nDiam: 140 cm\n\n\n\nTA-535(Struttura Ovale):\n\n\nAltezza: 75 cm\n\n\nLunghezza: 185 cm\n\n\nLarghezza: 115 cm',
                en:'Dining room table with base in curved plywood, oak or american walnut. Available mat lacquered. The base lays on a plate in chromium-plated or satined steel.\n\nTA-534:\nHeight: 75 cm\nDiam: 140 cm\n\nTA-535(Oval Structure):\nHeight: 75 cm\nLength: 185 cm\nWidth: 115 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'EVERY - TAVOLO ALLUNGABILE',
                en:'EVERY - EXTENDIBLE TABLE'
            },
            href:'every-linfa-design',
            descrizione:{
                it:'Tavolo da pranzo allungabile in legno di rovere o noce canaletto. Le allunghe laterali sono ripiegate su se stesse sotto il piano. Disponibile anche laccato lucido, inviare richiesta: info@sediedesign.it\n\n\n\nTA-538:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 182(+45+45) cm\n\n\nLarghezza: 90 cm\n\n\n\nTA-539:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 152(+60) cm\n\n\nLarghezza: 90 cm',
                en:'Extendible dining room table in oak or american walnut. The side extensions are folded under the table top. Also available glossy lacquered, send request.\n\nTA-538:\nHeight: 75 cm\nLength: 182(+45+45) cm\nWidth: 90 cm\n\nTA-539:\nHeight: 75 cm\nLength: 152(+60) cm\nWidth: 90 cm '
            },
            n_images:4
        },
        {
            title:{
                it:'GIUNONE -TAVOLO ALLUNGABILE',
                en:'GIUNONE - EXTENDIBLE TABLE'
            },
            href:'giunone-linfa-design',
            descrizione:{
                it:'Tavolo da pranzo allungabile in legno di rovere. Le allunghe laterali sono ripiegate su se stesse sotto il piano. Disponibile anche laccato lucido.\n\n\n\nTA-526:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 142(+60) cm\n\n\nLarghezza: 90 cm\n\n\n\nTA-526:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 172(+45+45) cm\n\n\nLarghezza: 90 cm',
                en:'Dining room extendible table in oak. The side extensions are folded under the table top. Also available glossy lacquered, send request.\n\nTA-526:\nHeight: 75 cm\nLength: 142(+60) cm\nWidth: 90 cm\n\nTA-536:\nHeight: 75 cm\nLength: 172(+45+45) cm\nWidth: 90 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'GIUNONE - TAVOLO CONSOLLE',
                en:'GIUNONE  - CONSOLLE TABLE'
            },
            href:'giunone-c-linfa-design',
            descrizione:{
                it:'Consolle trasformabile in tavolo in legno di rovere. Disponibile laccata opaca o lucida.\n\n\n\nTA-529:\n\n\nAltezza: 76/78 cm\n\n\nLunghezza: 140 cm\n\n\nLarghezza: 40/80 cm',
                en:'Consolle table in solid oak. Available mat or glossy lacquered.\n\nTA-529:\nHeight: 76/78 cm\nLength: 140 cm\nWidth: 40/80 cm'
            },
            n_images:4
        },
        {
            title:{
                it:'GIUNONE GLASS - TAVOLO',
                en:'GIUNONE GLASS - TABLE'
            },
            href:'giunone-gt-linfa-design',
            descrizione:{
                it:'Tavolo da pranzo con gambe in massello di rovere. Disponibile laccato lucido. Piano in cristallo temperato nei colori della collezione.\n\n\n\nTA-527:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 240 cm\n\n\nLarghezza: 100 cm\n\n\n\nTA-528:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 200 cm\n\n\nLarghezza: 100 cm\n\n\n\nTA-537:\n\n\nAltezza: 75 cm\n\n\nDiam: 140 cm',
                en:'Dining room table with tempered glass top according to our glass collection. Solid oak legs, mat or glossy lacquered. Also available table 200x100xH75 cm\n\n TA-527: \n Height: 75 cm \n Length: 240 cm \n Width: 100 cm \n\n TA-537: \n Height: 75 cm \n Diam: 140 cm'
            },
            n_images:6
        },
        {
            title:{
                it:'KARTESIO - TAVOLO ALLUNGABILE',
                en:'KARTESIO - EXTENDIBLE TABLE'
            },
            href:'kartesio-linfa-design',
            descrizione:{
                it:'Tavolo da pranzo allungabile in legno di rovere o noce canaletto. Disponibile laccato opaco l\'allunga centrale e? ripiegata su se stessa sotto il piano.\n\n\n\nTA-507:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 180(+50) cm\n\n\nLarghezza: 90 cm',
                en:'Extendible dining room table in oak or american walnut. Available mat lacquered. The central extension is folded under the top.\n\nTA-507:\nHeight: 75 cm\nLength: 180(+50) cm\nWidth: 90 cm'
            },
            n_images:2
        },
        {
            title:{
                it:'MIRAGGIO - TAVOLO ALLUNGABILE',
                en:'MIRAGGIO - EXTENDIBLE TABLE'
            },
            href:'miraggio-linfa-design',
            descrizione:{
                it:'Tavolo da pranzo allungabile in legno di rovere o noce canaletto, le allunghe laterali sono ripiegate su se stesse sotto il piano.\n\n\n\nTA-533:\n\n\nAltezza: 76 cm\n\n\nLunghezza: 180(+45+45) cm\n\n\nLarghezza: 90 cm\n\n\n\nTA-521:\n\n\nAltezza: 76 cm\n\n\nLunghezza: 150(+60) cm\n\n\nLarghezza: 90 cm',
                en:'Extendable dining room table in oak or american walnut. The side extensions are folded under the table top.\n\nTA-533:\nHeight: 76 cm\nLength: 180(+45+45) cm\nWidth: 90 cm\n\nTA-521:\nHeight: 76 cm\nLength: 150(+60) cm\nWidth: 90 cm'
            },
            n_images:5
        },
        {
            title:{
                it:'BRIDGE - CONSOLLE ALLUNGABILE',
                en:'BRIDGE - EXTENDIBLE CONSOLLE'
            },
            href:'linfa-design-bridge-ec',
            descrizione:{
                it:'Consolle Bridge EC Linfa Design: consolle allungabile in massello di rovere o noce canaletto. Disponibile anche nelle finiture laccate opache.\n\n\n\nDimensioni:\n\n\nAltezza 74 cm\n\n\nLunghezza Consolle Chiusa 120 cm - Consolle Aperta 43 cm\n\n\nProfondità Consolle Chiusa 193 cm - Consolle Aperta 120 cm',
                en:'Bridge EC Linfa Design Console: extendable console in solid oak or american walnut structure. Also available in matt lacquered finishes.\n\nDimensions:\nHeight 74 cm\nLength Closed Console 120 cm - Open Console 43 cm\nDepth Closed Console 193 cm - Open Console 120 cm'
            },
            n_images:2
        },
    ],
    'sedie-sgabelli':[
        {
            title:{
                it:'DEA - SEDIA',
                en:'DEA - CHAIR'
            },
            href:'dea-c-linfa-design',
            descrizione:{
                it:'Sedia con sedile imbottito. Struttura in massello di rovere o noce canaletto. Disponibile laccata opaca rivestimento nei tessuti della collezione.\n\n\n\nSE-522:\n\n\nAltezza: 83 cm\n\n\nAltezza Seduta: 46 cm\n\n\nLarghezza: 52,5 cm\n\n\nProfondità: 50 cm',
                en:'Chair in solid oak or american walnut structure with upholstered seat. Available mat lacquered. Cover according to our fabric collection.\n\nSE-522:\nHeight: 83 cm\nSeat Height: 46 cm\nWidth: 52,5 cm\nDepth: 50 cm'
            },
            n_images:4
        },
        {
            title:{
                it:'SANTORINI - SEDIA',
                en:'SANTORINI - CHAIR'
            },
            href:'santorini-linfa-design',
            descrizione:{
                it:'Sedia dal peso leggero (kg. 3) con struttura in massello di frassino. Seduta disponibile solo in legno.\n\n\n\nSE-531:\n\n\nAltezza: 83 cm\n\n\nAltezza Seduta: 45 cm\n\n\nLarghezza: 43 cm\n\n\nProfondità: 49,5 cm',
                en:'Chair light weight (kgs 3) solid ashwood seat available only in wood.\n\nSE-531:\nHeight: 83 cm\nSeat Height: 45 cm\nWidth: 43 cm\nDepth: 49,5 cm'
            },
            n_images:5
        },
        {
            title:{
                it:'MAK - SEDIA',
                en:'MAK . CHAIR'
            },
            href:'mak-linfa-design',
            descrizione:{
                it:'Sedia con base in legno massello di noce canaletto o rovere. Disponibile il cuscino con i tessuti della collezione.\n\n\n\nSE-530:\n\n\nAltezza: 80,5 cm\n\n\nAltezza Seduta: 45 cm\n\n\nLarghezza: 45 cm\n\n\nProfondità: 54 cm',
                en:'Chair with solid oak or american walnut frame. Seat frame in beechwood plywood american walnut or oak veneered.\n\nSE-530:\nHeight: 80,5 cm\nSeat Height: 45 cm\nWidth: 45 cm\nDepth: 54 cm'
            },
            n_images:8
        },
        {
            title:{
                it:'HALLEY - SEDIA',
                en:'HALLEY - CHAIR'
            },
            href:'halley-linfa-design',
            descrizione:{
                it:'Sedia con base in legno massello di noce canaletto o rovere. Disponibile laccata opaca o lucida scocca in multistrato di faggio imbottita. Rivestimento sfoderabile nei tessuti della collezione.\n\n\n\nSE-532:\n\n\nAltezza: 85 cm\n\n\nAltezza Seduta: 46 cm\n\n\nLarghezza: 48 cm\n\n\nProfondità: 53 cm',
                en:'Chair with solid oak or american walnut frame. Available mat or glossy lacquered. Seat frame in beech plywood, upholstered. Removable cover according to our fabric collection.\n\nSE-532:\nHeight: 85 cm\nSeat Height: 46 cm\nWidth: 48 cm\nDepth: 53 cm'
            },
            n_images:5
        },
        {
            title:{
                it:'TESI - SEDIA',
                en:'TESI . CHAIR'
            },
            href:'tesi-linfa-design',
            descrizione:{
                it:'Sedia con struttura in massello di rovere o noce canaletto struttura. Disponibile laccato opaco. Coprire secondo la nostra collezione di tessuti.\n\n\n\nSE-510:\n\n\nAltezza: 85 cm\n\n\nAltezza Seduta: 47 cm\n\n\nLarghezza: 51 cm\n\n\nProfondità: 46 cm',
                en:'Chair in solid oak or american walnut structure. Available mat lacquered. Cover according to our fabric collection.\n\nSE-510:\nHeight: 85 cm\nSeat Height: 47 cm\nWidth: 51 cm\nDepth: 46 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'STELLARIA - SEDIA',
                en:'STELLARIA - CHAIR'
            },
            href:'stellaria-linfa-design',
            descrizione:{
                it:'Sedia con struttura in massello di rovere o noce canaletto struttura. Disponibile laccato opaco. Coprire secondo la nostra collezione di tessuti.\n\n\n\nSE-520:\n\n\nAltezza: 80 cm\n\n\nAltezza Seduta: 46 cm\n\n\nLarghezza: 51,5 cm\n\n\nProfondità: 44,5 cm',
                en:'Chair in solid oak or american walnut structure. Available mat lacquered. Cover according to our fabric collection.\n\nSE-520:\nHeight: 80 cm\nSeat Height: 46 cm\nWidth: 51,5 cm\nDepth: 44,5 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'SOFIA - SEDIA',
                en:'SOFIA - CHAIR'
            },
            href:'sofia-linfa-design',
            descrizione:{
                it:'Sedia in massello di frassino o struttura in faggio. Disponibile laccato opaco. Coprire secondo la nostra collezione di tessuti.\n\n\n\nSE-523:\n\n\nAltezza: 99 cm\n\n\nAltezza Seduta: 46 cm\n\n\nLarghezza: 59 cm\n\n\nProfondità: 49 cm',
                en:'Chair in solid ash or beechwood structure. Available mat lacquered. Cover according to our fabric collection.\n\nSE-523:\nHeight: 99 cm\nSeat Height: 46 cm\nWidth: 59 cm\nDepth: 49 cm'
            },
            n_images:4
        },
        {
            title:{
                it:'SINGLE THREE - PANCA CON BRACCIOLI',
                en:'SINGLE THREE - ARMRESTS BENCH'
            },
            href:'single-three-linfa-design',
            descrizione:{
                it:'Panca in massello di rovere o noce canaletto con braccioli. Disponibile laccato opaco. Rivestimento sfoderabile secondo la nostra collezione di tessuti.\n\n\n\nSE-506(Seduta Singola):\n\n\nAltezza: 68 cm\n\n\nAltezza Seduta: 45 cm\n\n\nLunghezza: 144 cm\n\n\nProfondità: 48 cm\n\n\n\nSE-507(Seduta Doppia):\n\n\nAltezza: 68 cm\n\n\nAltezza Seduta: 45 cm\n\n\nLunghezza: 144 cm\n\n\nProfondità: 48 cm',
                en:'Bench in solid oak or american walnut structure with armrests. Available mat lacquered. Removable cover according to our fabric collection.\n\nSE-506(Single Seat):Height: 68 cm\nSeat Height: 45 cm\nLength: 144 cm\nWidth: 48 cm\n\nSE-507(Double Seat):\nHeight: 68 cm\nSeat Height: 45 cm\nLength: 144 cm\nWidth: 48 cm'
            },
            n_images:6
        },
        {
            title:{
                it:'SINGLE TWO',
                en:'SINGLE TWO'
            },
            href:'single-two-linfa-design',
            descrizione:{
                it:'Panca in solido rovere o noce canaletto. Disponibile laccato opaco. \n\n\n\nSE-504:\n\n\nAltezza: 45 cm\n\n\nAltezza Seduta: 45 cm\n\n\nLarghezza: 96 cm\n\n\nProfondità: 48 cm',
                en:'Bench in solid oak or american walnut structure. Available mat lacquered. Removable cover according to our fabric collection.\n\nSE-504:\nHeight: 45 cm\nSeat Height: 45 cm\nWidth: 96 cm\nDepth: 48 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'SINGLE ONE - SGABELLO',
                en:'SINGLE ONE - STOOL'
            },
            href:'single-one-s-linfa-design',
            descrizione:{
                it:'Sgabello in massello di rovere o noce canaletto. Disponibile laccato opaco (lucido a richiesta). Rivestimento sfoderabile secondo la nostra collezione di tessuti.\n\n\n\nSE-503:\n\n\nAltezza: 45 cm\n\n\nAltezza Seduta: 45 cm\n\n\nLarghezza: 48 cm\n\n\nProfondità: 48 cm',
                en:'Stool in solid oak or american walnut structure. Available mat lacquered (glossy on request). Removable cover according to our fabric collection.\n\nSE-503:\nHeight: 45 cm\nSeat Height: 45 cm\nWidth: 48 cm\nDepth: 48 cm'
            },
            n_images:4
        },
        {
            title:{
                it:'OPERA - POLTRONA',
                en:'OPERA - ARMCHAIR'
            },
            href:'opera-linfa-design',
            descrizione:{
                it:'Poltroncina in massello di rovere o noce canaletto struttura. Disponibile laccato opaco. Rivestita secondo la nostra collezione di tessuti.\n\n\n\nPL-505:\n\n\nAltezza: 67 cm\n\n\nAltezza Seduta: 44 cm\n\n\nLarghezza: 45 cm\n\n\nProfondità: 53 cm',
                en:'Armchair in solid oak or american walnut structure. Available mat lacquered. Cover according to our fabric collection.\n\nPL-505:\nHeight: 67 cm\nSeat Height: 44 cm\nWidth: 45 cm\nDepth: 53 cm'
            },
            n_images:5
        },
        {
            title:{
                it:'MORGANA - SEDIA',
                en:'MORGANA - CHAIR'
            },
            href:'morgana-linfa-design',
            descrizione:{
                it:'Sgabello con struttura in massello di rovere o noce canaletto, seduta curvo e schienale in legno chiaro. Laccato opaco disponibile. Poggiapiedi con piastra in acciaio satinato.\n\n\n\nSE-527:\n\n\nAltezza: 87 cm\n\n\nAltezza Seduta: 47 cm\n\n\nLarghezza: 60 cm\n\n\nProfondità: 47 cm',
                en:'Chair in solid oak or american walnut structure, available in mat or glossy lacquered. Removable cover according to our fabric collection.\n\nSE-527:\nHeight: 87 cm\nSeat Height: 47 cm\nWidth: 60 cm\nDepth: 47 cm'
            },
            n_images:4
        },
        {
            title:{
                it:'KART - SGABELLO',
                en:'KART - STOOL'
            },
            href:'kart-s-linfa-design',
            descrizione:{
                it:'Sgabello con struttura in massello di rovere o noce canaletto, seduta curvo e schienale in legno chiaro. Laccato opaco disponibile. Poggiapiedi con piastra in acciaio satinato.\n\n\n\nSE-529:\n\n\nAltezza: 94 cm\n\n\nAltezza Seduta: 60 cm\n\n\nLarghezza: 44,5 cm\n\n\nProfondità: 46 cm',
                en:'Stool with solid oak or american walnut structure, curved seat and back in light wood. Mat lacquered available. Footrest with satined steel plate.\n\nSE-529:\nHeight: 94 cm\nSeat Height: 60 cm\nWidth: 44,5 cm\nDepth: 46 cm'
            },
            n_images:2
        },
        {
            title:{
                it:'DOUBLE - SEDIA',
                en:'DOUBLE - CHAIR'
            },
            href:'double-linfa-design',
            descrizione:{
                it:'Sedia con base in legno massello di rovere o noce canaletto. Disponibile laccata opaca o lucida scocca in multistrato di faggio imbottita. Rivestimento sfoderabile nei tessuti della collezione.\n\n\n\nSE-526:\n\n\nAltezza: 85 cm\n\n\nAltezza Seduta: 46 cm\n\n\nLarghezza: 53 cm\n\n\nProfondità: 48 cm',
                en:'Chair with solid oak or american walnut structure. Available mat or glossy lacquered. Seat frame in beechwood plywood, upholstered. Removable cover according to our fabric collection.\n\nSE-526:\nHeight: 85 cm\nSeat Height: 46 cm\nWidth: 53 cm\nDepth: 48 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'FOREVER - SEDIA',
                en:'FOREVER - CHAIR'
            },
            href:'forever-linfa-design',
            descrizione:{
                it:'Sedia con struttura in massello di rovere, noce canaletto o struttura in faggio. Laccato opaco disponibile. Rivestito secondo la nostra collezione di tessuti.\n\n\n\nSE-524:\n\n\nAltezza: 82 cm\n\n\nAltezza Seduta: 47 cm\n\n\nLarghezza: 50 cm\n\n\nProfondità: 44,5 cm',
                en:'Chair in solid oak, american walnut or beechwood structure. Mat lacquered available. Cover according to our fabric collection.\n\nSE-524:\nHeight: 82 cm\nSeat Height: 47 cm\nWidth: 50 cm\nDepth: 44,5 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'EMPHASYS - SEDIA',
                en:'EMPHASYS - CHAIR'
            },
            href:'emphasys-linfa-design',
            descrizione:{
                it:'Sedia con struttura in massello di rovere o noce canaletto, con rivestimento nei tessuti della collezione.\n\n\n\nSE-500:\n\n\nAltezza: 85 cm\n\n\nAltezza Seduta: 45 cm\n\n\nLarghezza: 51 cm\n\n\nProfondità: 46 cm',
                en:'Chair in solid oak or american walnut structure with cover according to our fabric collection.\n\nSE-500:\nHeight: 85 cm\nSeat Height: 45 cm\nWidth: 51 cm\nDepth: 46 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'KART - SEDIA',
                en:'KART - CHAIR'
            },
            href:'kart-linfa-design',
            descrizione:{
                it:'Sedia e capo tavola con struttura in legno massello di rovere o noce canaletto seduta e schienale curvati in legno leggero. Disponibile laccata opaca.\n\n\n\nSE-501(Versione Sedia):\n\n\nAltezza: 82 cm\n\n\nAltezza Seduta: 47 cm\n\n\nLarghezza: 48 cm\n\n\nProfondità: 50 cm\n\n\n\nPL-500(Versione Poltrona):\n\n\nAltezza: 82 cm\n\n\nAltezza Seduta: 47 cm\n\n\nLarghezza: 48 cm\n\n\nProfondità: 53 cm',
                en:'Chair and armchair with solid oak or american walnut frame, curved seat and back in light wood. Mat lacquered available.\n\nSE-501(Chair Version):\nHeight: 82 cm\nSeat Height: 47 cm\nWidth: 48 cm\nDepth: 50 cm\n\nPL-500(Armchair Version):\nHeight: 82 cm\nSeat Height: 47 cm\nWidth: 48 cm\nDepth: 53 cm'
            },
            n_images:4
        },
    ],
    'credenze':[
        {
            title:{
                it:'LINE - CREDENZA',
                en:'LINE - SIDEBOARD'
            },
            href:'line-linfa-design',
            descrizione:{
                it:'Credenza con 2 ante complanari. Inserti in massello di rovere o noce canaletto in vari colori struttura in acciaio. Struttura box disponibile bianca o grigia.\n\n\n\nAltezza: 67,5 cm\n\n\nLunghezza: 240 cm\n\n\nProfondità: 52,5 cm',
                en:'Sideboard with 2 coplanar doors. Inserts in solid oak or American walnut in different colours, steel structure base. Box painted white or grey.\n\nHeight: 67,5 cm\nLength: 240 cm\nDepth: 52,5 cm'
            },
            n_images:4
        },
        {
            title:{
                it:'LINEA 2 - CREDENZA',
                en:'LINE 2 - SIDEBOARD'
            },
            href:'line2-linfa-design',
            descrizione:{
                it:'Credenza a 4 ante e base sospesa. Struttura in massello di rovere, disponibile in due misure.\n\n\n\nMB-520\n\n\nAltezza: 50 cm\n\n\nLunghezza: 200 cm\n\n\nProfondità: 55 cm\n\n\n\nMB-521\n\n\nAltezza: 78 cm\n\n\nLunghezza: 200 cm\n\n\nProfondità: 55 cm',
                en:'Sideboard with 4 doors and suspended base. Solid oak Structure, available in two sizes.\n\nMB-520\nHeight: 50 cm\nLength: 200 cm\nDepth: 55 cm\n\nMB-521\nHeight: 78 cm\nLength: 200 cm\nDepth: 55 cm'
            },
            n_images:2
        },
        {
            title:{
                it:'ADORNA - CREDENZA',
                en:'ADORNA - SIDEBOARD'
            },
            href:'adorna-sideboard-linfa-design',
            descrizione:{
                it:'Credenza in bianco opaco o lucido con ante e intaglio applicato. La base è in laccato cromato o bianco. Sarà consegnato montato. La base può essere avvitato dal cliente finale.\n\n\n\nMB-511:\n\n\nAltezza: 78 cm\n\n\nAltezza Box: 50 cm\n\n\nLunghezza: 200 cm\n\n\nProfondità: 55 cm',
                en:'Sideboard in white mat or glossy lacquered with doors and applied carving. The base is in chromium-plated or white lacquered. It will be delivered assembled.The base can be screwed by the final customer.\n\nMB-511:\nHeight: 78 cm\nBox Height: 50 cm\nLength: 200 cm\nDepth: 55 cm'
            },
            n_images:2
        },
        {
            title:{
                it:'CUBOX - CREDENZA',
                en:'CUBOX - SIDEBOARD'
            },
            href:'cubox-sideboard-linfa-design',
            descrizione:{
                it:'Credenza in bianco opaco o lucido con ante e maniglie cromo placcato. La base è in cromo lucido o laccato bianco. Sarà consegnato montato. La base può essere avvitato dal cliente finale.\n\n\n\nMB-509:\n\n\nAltezza: 78 cm\n\n\nAltezza Box: 50 cm\n\n\nLunghezza: 200 cm\n\n\nProfondità: 55 cm\n\n\n\nMB-510:\n\n\nAltezza: 54 cm\n\n\nAltezza Box: 50 cm\n\n\nLunghezza: 200 cm\n\n\nProfondità: 55 cm',
                en:'Sideboard in white mat or glossy lacquered with doors, and chromium- plated handles. The base is in chromium- plated or white lacquered. It will be delivered assembled.The base can be screwed by the final customer.\n\nMB-509:\nHeight: 78 cm\nBox Height: 50 cm\nLength: 200 cm\nDepth: 55 cm\n\nMB-510:\nHeight: 54 cm\nBox Height: 50 cm\nLength: 200 cm\nDepth: 55 cm'
            },
            n_images:2
        },
        {
            title:{
                it:'FAST - CREDENZA',
                en:'FAST - SIDEBOARD'
            },
            href:'fast-linfa-design',
            descrizione:{
                it:'Fast by Linfadesign is a sideboard in oak or american walnut with sliding doors and drawers. Solid wood base. \nIt will be delivered assembled. The base can be screwed by the final customer. \n\nMB-500: \nHeight: 80 cm \nLength: 180 cm \nDepth: 51 cm',
                en:'Fast by Linfadesign is a sideboard in oak or american walnut with sliding doors and drawers. Solid wood base. \nIt will be delivered assembled. The base can be screwed by the final customer. \n\nMB-500: \nHeight: 80 cm \nLength: 180 cm \nDepth: 51 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'INCISA - CREDENZA',
                en:'INCISA - SIDEBOARD'
            },
            href:'incisa-sideboard-linfa-design',
            descrizione:{
                it:'Credenza in laccato bianco opaco o lucido con ante battenti. La base puo? essere cromata o verniciata bianca. Consegnata montata con basamento da avvitare al momento della consegna al cliente finale\n\n\n\nMB-515(Versione Sospesa):\n\n\nAltezza Box: 50 cm\n\n\nLunghezza: 200 cm\n\n\nProfondità: 55 cm\n\n\n\nMB-516(Con Basamento):\n\n\nAltezza Box: 78 cm\n\n\nLunghezza: 200 cm\n\n\nProfondità: 55 cm',
                en:'Sideboard in white mat or glossy lacquered with doors. The base is in chromium-plated or white lacquered. It will be delivered assembled.The base can be screwed by the final customer.\n\nMB-515(Suspended Version):\nBox Height: 50 cm\nLength: 200 cm\nDepth: 55 cm\n\nMB-516(With Base):\nBox Height: 78 cm\nLength: 200 cm\nDepth: 55 cm'
            },
            n_images:2
        },
        {
            title:{
                it:'PRISMA - CREDENZA',
                en:'PRISMA - SIDEBOARD'
            },
            href:'prisma-side-linfa-design',
            descrizione:{
                it:'Credenza in laccato bianco opaco o lucido con ante battenti. La base puo? essere cromata o verniciata bianca. Consegnata montata con basamento da avvitare al momento della consegna al cliente finale.\n\n\n\nMB-513(Versione Sospesa):\n\n\nAltezza Box: 56 cm\n\n\nLunghezza: 200 cm\n\n\nProfondità: 55 cm\n\n\n\nMB-512(Con Base):\n\n\nAltezza Box: 72 cm\n\n\nLunghezza: 200 cm\n\n\nProfondità: 55 cm',
                en:'Sideboard in white mat or glossy lacquered with doors. The base is in chromium-plated or white lacquered. It will be delivered assembled.The base can be screwed by the final customer.\n\nMB-513(Suspended Version):\nBox Height: 56 cm\nLength: 200 cm\nDepth: 55 cm\n\nMB-512(with Base):\nBox Height: 72 cm\nLength: 200 cm\nDepth: 55 cm'
            },
            n_images:3
        }
    ],
    'poltrone-poufs':[
        {
            title:{
                it:'FRED BERGER - POLTRONA',
                en:'FRED BERGER - ARMCHAIR'
            },
            href:'fred-berger-linfa-design',
            descrizione:{
                it:'Poltroncina in massello di rovere o noce canaletto. Rivestimento sfoderabile secondo la nostra collezione di tessuti.\n\n\n\nAltezza: 113 cm\n\n\nLarghezza: 83 cm\n\n\nProfondità: 77 cm',
                en:'Armchair in solid oak or american walnut. Removable cover according to our fabric collection.\n\nHeight: 113 cm\nWidth: 83 cm\nDepth: 77 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'MIA - POLTRONA',
                en:'MIA - ARMCHAIR'
            },
            href:'mia-linfa-design',
            descrizione:{
                it:'Poltroncina con base in legno massello di noce canaletto o rovere. Scocca in multistrato di faggio imbottita. Rivestimento sfoderabile nei tessuti della collezione.\n\n\n\nPL-513:\n\n\nAltezza: 80 cm\n\n\nAltezza Seduta: 45 cm\n\n\nAltezza Braccioli: 68 cm\n\n\nLarghezza: 55 cm\n\n\nProfondità: 58 cm',
                en:'Armchair with solid oak or american walnut frame. Seat frame in beechwood plywood, upholstered. Removable cover according to our fabric collection.\n\nPL-513:\nHeight: 80 cm\nSeat Height: 45 cm\nArmrests Height: 68 cm\nWidth: 55 cm\nDepth: 58 cm'
            },
            n_images:11
        },
        {
            title:{
                it:'STATUS - POLTRONA',
                en:'STATUS - ARMCHAIR'
            },
            href:'status-linfa-design',
            descrizione:{
                it:'Poltroncina in massello di rovere o noce canaletto. Disponibile laccato opaco. Rivestimento sfoderabile secondo la nostra collezione di tessuti.\n\n\n\nPL-503:\n\n\nAltezza: 78 cm\n\n\nAltezza Seduta: 41 cm\n\n\nAltezza Braccioli: 60 cm\n\n\nLarghezza: 74 cm\n\n\nProfondità: 85 cm',
                en:'Armchair in solid oak or american walnut. Available mat lacquered. Removable cover according to our fabric collection.\n\nPL-503:\nHeight: 78 cm\nSeat Height: 41 cm\nArmrests Height: 60 cm\nWidth: 74 cm\nDepth: 85 cm'
            },
            n_images:4
        },
        {
            title:{
                it:'ROUND - POLTRONA GIREVOLE',
                en:'ROUND - SWIVEL ARMCHAIR'
            },
            href:'round-swivel-linfa-design',
            descrizione:{
                it:'Poltrona girevole in legno massello con schienale in multistrato curvato. Base in acciaio cromato. Disponibile laccato opaco. Rivestimento sfoderabile secondo la nostra collezione di tessuti.\n\n\n\nPL-506:\n\n\nAltezza: 65 cm\n\n\nAltezza Seduta: 38 cm\n\n\nDiam.: 87 cm',
                en:'Swivel armchair in solid wood with curved back in plywood. Base in chromium-plated steel. Available mat lacquered. Removable cover according to our fabric collection.\n\nPL-506:\nHeight: 65 cm\nSeat Height: 38 cm\nDiam.: 87 cm'
            },
            n_images:4
        },
        {
            title:{
                it:'PIPER - POLTRONA',
                en:'PIPER - ARMCHAIR'
            },
            href:'piper-linfa-design',
            descrizione:{
                it:'Poltrona in multistrato curvato in rovere o noce canaletto impiallacciato. Disponibile laccata opaca disponibile. Coprire secondo la nostra collezione di tessuti.\n\n\n\nPL-509:\n\n\nAltezza: 82 cm\n\n\nAltezza Seduta: 40 cm\n\n\nLarghezza: 65 cm\n\n\nProfondità: 75,5 cm',
                en:'Armchair in curved plywood, oak or american walnut veneered. Available mat lacquered available. Cover according to our fabric collection.\n\nPL-509:\nHeight: 82 cm\nSeat Height: 40 cm\nWidth: 65 cm\nDepth: 75,5 cm'
            },
            n_images:4
        },
        {
            title:{
                it:'MI/AMI - DIVANO',
                en:'MI/AMI - SOFA'
            },
            href:'mi-ami-sofa-linfa-design',
            descrizione:{
                it:'Divano due posti in legno massello di rovere, noce canaletto o faggio, disponibile in laccato opaco. Coprire secondo la nostra collezione di tessuti.\n\n\n\nSE-502:\n\n\nAltezza: 88 cm\n\n\nAltezza Seduta: 42 cm\n\n\nAltezza Braccioli: 64 cm\n\n\nLunghezza: 125 cm\n\n\nProfondità: 72 cm',
                en:'Two seater sofa in solid oak, american walnut or beechwood, available in mat lacquered. Cover according to our fabric collection.\n\nSE-502:\nHeight: 88 cm\nSeat Height: 42 cm\nArmrests Height: 64 cm\nLength: 125 cm\nDepth: 72 cm'
            },
            n_images:4
        },
        {
            title:{
                it:'MI/AMI - POLTRONA',
                en:'MI/AMI - ARMCHAIR'
            },
            href:'mi-ami-linfa-design',
            descrizione:{
                it:'Poltrona in legno massello di rovere, noce canaletto o faggio, disponibile in laccato opaco copertina secondo il nostro campionario.\n\n\n\nSE-501:\n\n\nAltezza: 88 cm\n\n\nAltezza Seduta: 42 cm\n\n\nAltezza Braccioli: 64 cm\n\n\nLarghezza: 72 cm\n\n\nProfondità: 66 cm',
                en:'Armchair in solid oak, american walnut or beechwood, available in mat lacquered Cover according to our fabric collection.\n\nSE-501:\nHeight: 88 cm\nSeat Height: 42 cm\nArmrests Height: 64 cm\nWidth: 72 cm\nDepth: 66 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'LIETA - POLTRONA',
                en:'LIETA - ARMCHAIR'
            },
            href:'lieta-linfa-design',
            descrizione:{
                it:'Poltroncina in massello di rovere o noce canaletto (in legno di rosa su richiesta). Rivestimento sfoderabile solo sul sedile, secondo il nostro campionario.\n\n\n\nSE-508:\n\n\nAltezza: 79 cm\n\n\nAltezza Seduta: 42 cm\n\n\nAltezza Braccioli: 64 cm\n\n\nLarghezza: 80 cm\n\n\nProfondità: 67 cm',
                en:'Armchair in solid oak or american walnut (in rosewood on request). Removable cover just on seat, according to our fabric collection.\n\nSE-508:\nHeight: 79 cm\nSeat Height: 42 cm\nArmrests Height: 64 cm\nWidth: 80 cm\nDepth: 67 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'G.ROUND - POUFF GIREVOLE',
                en:'G.ROUND - SWIVEL POUFF'
            },
            href:'g-round-linfa-design',
            descrizione:{
                it:'Pouff girevole in massello di rovere. Base in acciaio cromato. Disponibile laccato opaco. Rivestimento sfoderabile secondo la nostra collezione di tessuti.\n\n\n\nSE-514:\n\n\nAltezza Seduta: 38 cm\n\n\nDiam.: 87 cm',
                en:'Swivel pouff in solid oak. Base in chromium-plated steel. Available mat lacquered. Removable cover according to our fabric collection.\n\nSE-514:\nSeat Height: 38 cm\nDiam.: 87 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'FFOUP/4 - POUFF',
                en:'FFOUP/4 - POUFF'
            },
            href:'ffoup-4-linfa-design',
            descrizione:{
                it:'Pouff con massello di rovere o noce canaletto di base, disponibile in laccato opaco o lucido. Rivestimento sfoderabile secondo la nostra collezione di tessuti.\n\n\n\nSE-513:\n\n\nAltezza: 45 cm\n\n\nLarghezza: 90 cm\n\n\nProfondità: 90 cm',
                en:'Pouff with solid oak or american walnut base, available in mat or glossy lacquered. Removable cover according to our fabric collection.\n\nSE-513:\nHeight: 45 cm\nWidth: 90 cm\nDepth: 90 cm'
            },
            n_images:4
        },
        {
            title:{
                it:'FFOUP/2 - POUFF',
                en:'FFOUP/2 - POUFF'
            },
            href:'ffoup-2-linfa-design',
            descrizione:{
                it:'Pouff con massello di rovere o noce canaletto di base, disponibile in laccato opaco o lucido. Rivestimento sfoderabile secondo la nostra collezione di tessuti.\n\n\n\nSE-512:\n\n\nAltezza: 45 cm\n\n\nLunghezza: 90 cm\n\n\nProfondità: 45 cm',
                en:'Pouff with solid oak or american walnut base, available in mat or glossy lacquered. Removable cover according to our fabric collection.\n\nSE-512:\nHeight: 45 cm\nLength: 90 cm\nDepth: 45 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'FFOUP/1 - POUFF',
                en:'FFOUP/1 - POUFF'
            },
            href:'ffoup-1-linfa-design',
            descrizione:{
                it:'Pouff con massello di rovere o noce canaletto di base, disponibile in laccato opaco o lucido. Rivestimento sfoderabile secondo la nostra collezione di tessuti.\n\n\n\nSE-511:\n\n\nAltezza: 45 cm\n\n\nLarghezza: 45 cm\n\n\nProfondità: 45 cm',
                en:'Pouff with solid oak or american walnut base, available in mat or glossy lacquered. Removable cover according to our fabric collection.\n\nSE-511:\nHeight: 45 cm\nWidth: 45 cm\nDepth: 45 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'BOOMERANG - CHAISE LOUNGE',
                en:'BOOMERANG - CHAISE LOUNGE'
            },
            href:'boomerang-linfa-design',
            descrizione:{
                it:'Chaise longue in massello di rovere o noce canaletto,  sfoderabile secondo la nostra collezione di tessuti.\n\n\n\nSE-519:\n\n\nAltezza: 76 cm\n\n\nAltezza Seduta: 20 cm\n\n\nLarghezza: 170 cm\n\n\nProfondità: 64 cm',
                en:'Chaise lounge in solid oak or american walnut structure with removable cover according to our fabric collection.\n\nSE-519:\nHeight: 76 cm\nSeat Height: 20 cm\nWidth: 170 cm\nDepth: 64 cm'
            },
            n_images:5
        },
        {
            title:{
                it:'GINGER - POLTRONA',
                en:'GINGER - ARMCHAIR'
            },
            href:'ginger-linfa-design',
            descrizione:{
                it:'Poltroncina in massello di rovere o noce canaletto. Rivestimento sfoderabile secondo la nostra collezione di tessuti.\n\n\n\nAltezza: 80 cm\n\n\nLarghezza: 83 cm\n\n\nProfondità: 77 cm',
                en:'Armchair in solid oak or american walnut. Removable cover according to our fabric collection.\n\nHeight: 80 cm\nWidth: 83 cm\nDepth: 77 cm'
            },
            n_images:6
        },
        {
            title:{
                it:'TERESA - POLTRONA',
                en:'TERESA - ARMCHAIR'
            },
            href:'teresa-linfa-design',
            descrizione:{
                it:'Poltrona in legno massello di frassino o noce canaletto. Rivestimento sfoderabile nei tessuti della collezione.\n\n\n\nPL-512:\n\n\nAltezza: 72 cm\n\n\nAltezza Seduta: 42 cm\n\n\nAltezza Braccioli: 47 cm\n\n\nLarghezza: 74 cm\n\n\nProfondità: 75,5 cm',
                en:'Armchair in solid ash in mat or american walnut. Removable cover according to our fabric collection.\n\nPL-512:\nHeight: 72 cm\nSeat Height: 42 cm\nArmrests Height: 47 cm\nWidth: 74 cm\nDepth: 75,5 cm'
            },
            n_images:7
        }
    ],
    'tavolini':[
        {
            title:{
                it:'A.ROUND - TAVOLINO',
                en:'A.ROUND - COFFEE TABLE'
            },
            href:'a-round-linfa-design',
            descrizione:{
                it:'Tavolino in massello di rovere. Base in acciaio cromato. Disponibile laccato opaco.\n\n\n\nTI-518:\n\n\nAltezza: 27 cm\n\n\nDiam.: 87 cm',
                en:'Coffee table in solid oak. Base in chromium-plated steel. Available mat lacquered.\n\nTI-518:\nHeight: 27 cm\nDiam.: 87 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'RIGODRITTO - TAVOLINO',
                en:'RIGODRITTO - COFFEE TABLE'
            },
            href:'rigodritto-cf-linfa-design',
            descrizione:{
                it:'Tavolino da salotto in rovere o noce canaletto. Disponibile laccato opaco.\n\n\n\nTI-512:\n\n\nAltezza: 15 cm\n\n\nLunghezza: 90 cm\n\n\nLarghezza: 90 cm\n\n\n\nTI-513:\n\n\nAltezza: 32 cm\n\n\nLunghezza: 90 cm\n\n\nLarghezza: 90 cm\n\n\n\nTI-514:\n\n\nAltezza: 15 cm\n\n\nLunghezza: 120 cm\n\n\nLarghezza: 70 cm\n\n\n\nTI-515:\n\n\nAltezza: 32 cm\n\n\nLunghezza: 120 cm\n\n\nLarghezza: 70 cm\n\n\n\nTI-516:\n\n\nAltezza: 15 cm\n\n\nLunghezza: 120 cm\n\n\nLarghezza: 120 cm\n\n\n\nTI-517:\n\n\nAltezza: 32 cm\n\n\nLunghezza: 120 cm\n\n\nLarghezza: 120 cm',
                en:'Coffee table in oak or american walnut. Available mat lacquered.\n\nTI-512:\nHeight: 15 cm\nLength: 90 cm\nWidth: 90 cm\n\nTI-513:\nHeight: 32 cm\nLength: 90 cm\nWidth: 90 cm\n\nTI-514:\nHeight: 15 cm\nLength: 120 cm\nWidth: 70 cm\n\nTI-515:\nHeight: 32 cm\nLength: 120 cm\nWidth: 70 cm\n\nTI-516:\nHeight: 15 cm\nLength: 120 cm\nWidth: 120 cm\n\nTI-517:\nHeight: 32 cm\nLength: 120 cm\nWidth: 120 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'RAZIO LEGNO - TAVOLINO',
                en:'RAZIO WOOD - COFFEE TABLE'
            },
            href:'razio-cf-linfa-design',
            descrizione:{
                it:'Tavolino in rovere o noce canaletto. Disponibile laccato tutto opaco o con struttura opaca e piano lucido. Disponibile anche nella versione con struttura opaca e piano lucido ed è disponibile anche nella versione TI-511, inviare richiesta.\n\n\n\nTI-505:\n\n\nAltezza: 38 cm\n\n\nLunghezza: 68 cm\n\n\nLarghezza: 68 cm\n\n\n\nTI-507:\n\n\nAltezza: 23 cm\n\n\nLunghezza: 130 cm\n\n\nLarghezza: 60 cm\n\n\n\nTI-509:\n\n\nAltezza: 23 cm\n\n\nLunghezza: 120 cm\n\n\nLarghezza: 120 cm',
                en:'Coffee table in oak or american walnut. Available completely mat lacquered or base mat and top glossy lacquered. Also available with a matt structure and polished top and is also available TI-511, send request.\n\nTI-505:\nHeight: 38 cm\nLength: 68 cm\nWidth: 68 cm\n\nTI-507:\nHeight: 23 cm\nLength: 130 cm\nWidth: 60 cm\n\nTI-509:\nHeight: 23 cm\nLength: 120 cm\nWidth: 120 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'RAZIO GLASS - TAVOLINO',
                en:'RAZIO GLASS - COFFEE TABLE'
            },
            href:'razio-gct-linfa-design',
            descrizione:{
                it:'Tavolino con base in massello di rovere o noce canaletto. Base disponibile laccato opaco piano in cristallo (spessore 10mm) nei colori della collezione.\n\n\n\nTI-504:\n\n\nAltezza: 38 cm\n\n\nLunghezza: 68 cm\n\n\nLarghezza: 68 cm\n\n\n\nTI-506:\n\n\nAltezza: 23 cm\n\n\nLunghezza: 130 cm\n\n\nLarghezza: 60 cm',
                en:'Coffee table with solid oak or american walnut base. Base available mat lacquered. Glass top (mm 10) according to our glass collection.\n\nTI-504:\nHeight: 38 cm\nLength: 68 cm\nWidth: 68 cm\n\nTI-506:\nHeight: 23 cm\nLength: 130 cm\nWidth: 60 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'MASTER - TAVOLINO',
                en:'MASTER - COFFEE TABLE'
            },
            href:'master-linfa-design',
            descrizione:{
                it:'Tavolino da salotto con telaio in legno massello e piano in MDF, laccato opaco nei colori della collezione.\n\n\n\nTI-530:\n\n\nAltezza: 25 cm\n\n\nLunghezza: 130 cm\n\n\nLarghezza: 60 cm',
                en:'Coffee table with solid wood frame and MDF top. Available mat lacquered.\n\nTI-530:\nHeight: 25 cm\nLength: 130 cm\nWidth: 60 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'MASTER LEGNO - TAVOLINO',
                en:'WOOD MASTER - COFFEE TABLE'
            },
            href:'wood-master-linfa-design',
            descrizione:{
                it:'Tavolino da salotto con telaio in legno massello di rovere, noce canaletto o laccato opaco. \n\nPiano cristallo temperato ( sp mm 8) nei colori della collezione.\n\n\n\n\nTI-529:\n\nAltezza: 25 cm\n\nLunghezza: 130 cm\n\nLarghezza: 60 cm',
                en:'Coffee table with solid oak or american walnut frame. Available mat lacquered. Table top in tempered glass (mm 8) according to our glass collection.\n\n\n\nTI-529:\nHeight: 25 cm\nLength: 130 cm\nWidth: 60 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'MARNA - TAVOLINO',
                en:'MARNA - COFFEE TABLE'
            },
            href:'marna-linfa-design',
            descrizione:{
                it:'Tavolino pieghevole in rovere o noce canaletto. Disponibile laccato opaco.\n\n\n\nTI-526:\n\n\nAltezza: 23 cm\n\n\nLunghezza: 130 cm\n\n\nLarghezza: 60 cm',
                en:'Folded coffee table in oak or american walnut. Available mat lacquered.\n\nTI-526:\nHeight: 23 cm\nLength: 130 cm\nWidth: 60 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'GIUNONE GLASS - TAVOLINO',
                en:'GIUNONE GLASS - COFFEE TABLE'
            },
            href:'giunone-gt-linfa-design',
            descrizione:{
                it:'Tavolo da pranzo con gambe in massello di rovere. Disponibile laccato lucido. Piano in cristallo temperato nei colori della collezione.\n\n\n\nTA-527:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 240 cm\n\n\nLarghezza: 100 cm\n\n\n\nTA-528:\n\n\nAltezza: 75 cm\n\n\nLunghezza: 200 cm\n\n\nLarghezza: 100 cm\n\n\n\nTA-537:\n\n\nAltezza: 75 cm\n\n\nDiam: 140 cm',
                en:'Round coffee table with tempered glass top (mm 10) according to our glass collection. Solid wood legs, mat or glossy lacquered.\n\nTI-525:\nHeight: 30 cm\nDiam.: 100 cm'
            },
            n_images:6
        },
        {
            title:{
                it:'GIUNONE - TAVOLINO',
                en:'GIUNONE - COFFEE TABLE'
            },
            href:'giunone-ct-linfa-design',
            descrizione:{
                it:'Tavolino in rovere, laccato opaco o lucido.\n\n\n\nTI-523(Versione Quadrata):\n\n\nAltezza: 36 cm\n\n\nLunghezza: 90 cm\n\n\nLarghezza: 90 cm\n\n\n\nTI-524(Versione Rettangolare):\n\n\nAltezza: 36 cm\n\n\nLunghezza: 130 cm\n\n\nLarghezza: 60 cm',
                en:'Coffee table in oak, mat or glossy lacquered.\n\nTI-523(Square Version):\nHeight: 36 cm\nLength: 90 cm\nWidth: 90 cm\n\nTI-524(Rectangular Version):\nHeight: 36 cm\nLength: 130 cm\nWidth: 60 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'FRIZZANTE - TAVOLINO',
                en:'FRIZZANTE - COFFEE TABLE'
            },
            href:'frizzante-ct-linfa-design',
            descrizione:{
                it:'Tavolino con ruote. Struttura in laccato opaco e laccato lucido.\n\n\n\nTI-521:\n\n\nAltezza: 32 cm\n\n\nLunghezza: 120 cm\n\n\nLarghezza: 60 cm\n\n\n\nTI-519:\n\n\nAltezza 32 cm\n\n\nLunghezza: 92 cm\n\n\nLarghezza: 92 cm',
                en:'Coffee table with castors. Mat lacquered frame and glossy lacquered top.\n\nTI-521:\nAltezza: 32 cm\nLunghezza: 120 cm\nLarghezza: 60 cm\n\nTI-519:\nHeight: 32 cm\nLength: 92 cm\nWidth: 92 cm'
            },
            n_images:3
        },
        {
            title:{
                it:'EASY - TAVOLINO',
                en:'EASY - SIDETABLE'
            },
            href:'easy-side-linfa-design',
            descrizione:{
                it:'Tavolino con base in massello di rovere o noce canaletto, alto lucido, disponibile laccato lucido in base alla nostra collezione lacca.\n\n\n\nTI-527(Versione Scudo):\n\n\nAltezza: 52 cm\n\n\nLunghezza: 54 cm\n\n\nProfondità: 54 cm\n\n\n\nTI-528(Versione Rotonda):\n\n\nAltezza: 52 cm\n\n\nDiam.: 52 cm',
                en:'Side-table with base in solid oak or american walnut, glossy top, available glossy lacquered according to our lacquer collection.\n\nTI-527(Shield Version):\nHeight: 52 cm\nLength: 54 cm\nWidth: 54 cm\n\nTI-528(Round Version):\nHeight: 52 cm\nDiam.: 52 cm'
            },
            n_images:4
        },
        {
            title:{
                it:'BOLLICINE - SERVETTO CON RUOTE',
                en:'BOLLICINE - STORAGE TABLE'
            },
            href:'bollicine-linfa-design',
            descrizione:{
                it:'Servetto con ruote . Specchi rotondi per un disegno coreografico. Legno di rovere, noce canaletto o laccato opaco.\n\n\n\nVA-543:\n\n\nAltezza: 45 cm\n\n\nLunghezza: 40 cm\n\n\nLarghezza: 40 cm',
                en:'Storage table with castors and round mirrors for a choreographic effect. In oak, american walnut or mat lacquered.\n\nVA-543:\nHeight: 45 cm\nLength: 40 cm\nWidth: 40 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'SATURNO - TAVOLINO',
                en:'SATURNO - COFFEE TABLE'
            },
            href:'saturno-cf-linfa-design',
            descrizione:{
                it:'Tavolino da salotto in rovere o noce canaletto.\n\n\n\nTI-531(Versione Alta):\n\n\nAltezza: 44 cm\n\n\nDiam.: 50 cm\n\n\n\nTI-532(Versione Bassa):\n\n\nAltezza: 27 cm\n\n\nDiam.: 70 cm',
                en:'Coffee table in solid oak or american walnut.\n\nTI-531(High Version):\nHeight: 44 cm\nDiam.: 50 cm\n\nTI-532(Low Version):\n\nHeight: 27 cm\nDiam.: 70 cm'
            },
            n_images:2
        }
    ],
    'librerie':[
        {
            title:{
                it:'TOTEM BOLLICINE - LIBRERIA',
                en:'TOTEM BOLLICINE - BOOKCASE'
            },
            href:'bollicine-tot-linfa-design',
            descrizione:{
                it:'Libreria con contenitori quadrati girevoli in rovere o noce canaletto. Disponibile laccata opaca. A richiesta è possibile avere la laccatura con colori non uguali sui contenitori. Specchi rotondi per un disegno coreografico.\n\n\n\nAltezza: 169 cm.\n\n\nLarghezza: 40 cm.\n\n\nProfondità: 40 cm.',
                en:'Column with swivel square containers in oak or american walnut. Available mat lacquered. On request it is possible to have each container in different colours. Round mirrors for a choreographic effect.\n\nHeight: 169 cm.\nWidth: 40 cm.\nDepth: 40 cm.'
            },
            n_images:2
        },
        {
            title:{
                it:'DNA - LIBRERIA',
                en:'DNA - BOOKCASE'
            },
            href:'dna-linfadesign',
            descrizione:{
                it:'Libreria in multistrato curvato in legno di faggio con telaio in acciaio verniciato nero goffrato. Può essere utilizzata come divisorio in centro stanza oppure contro la parete.\n\n\n\nAltezza: 182 cm.\n\n\nLarghezza: 104 cm.\n\n\nProfondità: 38 cm.',
                en:'Bookcase in curved plywood, beech veneered with black finished steel frame. It can be used as room divider or against the wall.\n\nHeight: 182 cm.\nWidth: 104 cm.\nDepth: 38 cm. '
            },
            n_images:4
        },
        {
            title:{
                it:'ASSIOMA - LIBRERIA',
                en:'ASSIOMA - BOOKCASE'
            },
            href:'libreria-assioma-linfadesign',
            descrizione:{
                it:'Libreria con struttura in legno massello di rovere o noce canaletto, ripiani in legno di rovere,  noce canaletto o laccato opachi (su richiesta anche lucidi).\n\n\n\nAltezza: 192 cm.\n\n\nLarghezza: 180 cm.\n\n\nProfondità: 38 cm.',
                en:'Shelving unit with solid oak or american walnut frame. Shelves in oak, american walnut or mat lacquered (glossy lacquered on request). \n\nHeight: 192 cm.\nWidth: 180 cm.\nDepth: 38 cm. '
            },
            n_images:2
        },
        {
            title:{
                it:'SIPARIO - LIBRERIA',
                en:'SIPARIO - BOOKSHELVES'
            },
            href:'sipario-linfadesign',
            descrizione:{
                it:'Libreria con struttura in legno massello di rovere o noce canaletto ripiani laccati opachi o lucidi.\n\n\n\nAltezza: 164 cm.\n\n\nLarghezza: 160 cm.\n\n\nProfondità: 36 cm.',
                en:'Bookshelves with solid oak or american walnut frame. Shelves mat or glossy lacquered.\n\nHeight: 164 cm.\nWidth: 160 cm.\nDepth: 36 cm. '
            },
            n_images:2
        },
        {
            title:{
                it:'PRISMA - PENSILE A MURO',
                en:'PRISMA - WALL SHELF'
            },
            href:'prisma-linfa-design',
            descrizione:{
                it:'Pensile a muro disponibile solo laccato opaco o lucido. In tutti i colori della nostra collezione.\n\n\n\nMB-514:\n\n\nAltezza: 50 cm\n\n\nLunghezza: 50 cm\n\n\nProfondità: 38 cm',
                en:'Wall shelf mat or glossy lacquered according to our finishing collection.\n\nMB-514:\nHeight: 50 cm\nLength: 50 cm\nDepth: 38 cm'
            },
            n_images:3
        }
    ],
    'mensole':[
        {
            title:{
                it:'ACROBAT - MENSOLA',
                en:'ACROBAT - SHELF'
            },
            href:'acrobat-shelf-linfa-design',
            descrizione:{
                it:'Mensola in rovere, noce canaletto o laccata opaca.\n\n\n\nVA-554 DX:\n\n\nAltezza: 25 cm\n\n\nLunghezza: 120 cm\n\n\nProfondità: 30 cm\n\n\n\nVA-555 SX:\n\n\nAltezza: 18 cm\n\n\nLunghezza: 55 cm\n\n\nProfondità: 22 cm',
                en:'Shelf in oak, american walnut or mat lacquered.\n\nVA-554 DX:\nHeight: 25 cm\nLength: 120 cm\nDepth: 30 cm\n\nVA-555 SX:\nHeight: 18 cm\nLength: 55 cm\nDepth: 22 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'TANK - MENSOLA',
                en:'TANK - SHELF'
            },
            href:'tank-linfa-design',
            descrizione:{
                it:'Mensola in multistrato curvato in rovere o noce canaletto da montare orizzontalmente. I cilindri sono laccati opachi nei colori della collezione.\n\n\n\nVA-548:\n\n\nAltezza: 23 cm\n\n\nLunghezza: 138 cm\n\n\nLarghezza: 20 cm',
                en:'Shelf in curved oak or american walnut plywood, to hang horizontally. The cylinders are mat lacquered according to our lacquer collection.\n\nVA-548:\nHeight: 23 cm\nLength: 138 cm\nWidth: 20 cm'
            },
            n_images:1
        }
    ],
    'specchiere':[
        {
            title:{
                it:'AZIMUT -SPECCHIO',
                en:'AZIMUT - MIRROR'
            },
            href:'azimut-linfa-design',
            descrizione:{
                it:'Specchio con cornice in massello di rovere o noce canaletto disponibile laccata opaca.\n\n\n\nVA-503:\n\n\nAltezza: 180 cm\n\n\nLarghezza: 35 cm\n\n\nSpessore: 2,5 cm',
                en:'Mirror with solid oak or american walnut frame. Available in mat lacquered.\n\nVA-503:\nHeight: 180 cm\nWidth: 35 cm\nDepth: 2,5 cm'
            },
            n_images:2
        },
        {
            title:{
                it:'DORIAN - SPECCHIO',
                en:'DORIAN - MIRROR'
            },
            href:'dorian-linfa-design',
            descrizione:{
                it:'Specchio con cornice in massello di rovere o noce canaletto disponibile laccata opaca. Disponibile mensola in legno massello di rovere, noce canaletto o laccato opaco.\n\n\n\nVA-504:\n\n\nAltezza: 116 cm\n\n\nLarghezza: 80 cm\n\n\nSpessore: 4 cm\n\n\n\nVA-505:\n\n\nAltezza: 2 cm\n\n\nLarghezza: 75 cm\n\n\nSpessore: 18 cm',
                en:'Mirror with solid wood frame in oak, walnut or mat lacquered. Available shelf in solid oak, walnut or mat lacquered.\n\nMIRROR:\nHeight: 116 cm\nWidth: 80 cm\nDepth. 4 cm\n\nSHELF:\nHeight: 2 cm\nWidth: 75 cm\nDepth: 18 cm'
            },
            n_images:2
        },
        {
            title:{
                it:'GIUNONE - SPECCHIO',
                en:'GIUNONE - MIRROR'
            },
            href:'giunone-m-linfa-design',
            descrizione:{
                it:'Specchiera da parete rettangolare con inserto in rovere, laccato opaco o lucido.\n\n\n\nVA-547:\n\n\nAltezza: 40 cm\n\n\nLunghezza: 140 cm\n\n\nSpessore: 2,5 cm',
                en:'Rectangular wall mirror with oak, and mat or glossy lacquered decoration.\n\nVA-547:\nHeight: 40 cm\nLength: 140 cm\nDepth: 2,5 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'MOLECOLA - SPECCHIO',
                en:'MOLECOLA - MIRROR'
            },
            href:'molecola-linfa-design',
            descrizione:{
                it:'Specchio in rovere o noce canaletto, disponibili anche laccati opachi. Usati separatamente oppure in composizione.\n\n\n\nVA-523:\n\n\nAltezza: 30 cm\n\n\nLunghezza: 30 cm\n\n\nProfondità: 3 cm',
                en:'Mirror in oak or american walnut, available in mat lacquered. To be separately used or in multiple compositions.\n\nVA-523:\nHeight: 30 cm\nLength: 30 cm\nDepth: 3 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'LUNA - SPECCHIO',
                en:'LUNA - MIRROR'
            },
            href:'luna-linfa-design',
            descrizione:{
                it:'Specchio in rovere o noce canaletto con possibilita? di appenderlo in piu? posizioni (N3) disponibile laccata opaca e lucida.\n\n\n\nVA-521:\n\n\nAltezza Totale: 85 cm\n\n\nLunghezza: 100 cm\n\n\nDiam.: 70 cm\n\n\nSpessore: 5 cm\n\n\n\nVA-522:\n\n\nAltezza Totale 51 cm\n\n\nLunghezza: 60 cm\n\n\nDiam.: 42 cm\n\n\nSpessore: 4 cm',
                en:'Mirror in oak or american walnut. It is possible to hang it in 3 different positions. Available in mat or glossy lacquered.\n\nVA-521:\nTotal Height: 85 cm\nLength: 100 cm\nDiam.: 70 cm\nDepth: 5 cm\n\nVA-522:\nTotal Height: 51 cm\nLength: 60 cm\nDiam.: 42 cm\nDepth: 4 cm'
            },
            n_images:2
        },
        {
            title:{
                it:'WING - SPECCHIO',
                en:'WING - MIRROR'
            },
            href:'wing-linfa-design',
            descrizione:{
                it:'Specchio da parete con pannello in multistrato curvato in rovere o laccato con mensole sul retro possibilita? di applicare l\'appendi abito plug-in (n3).\n\n\n\nVA-537:\n\n\nAltezza: 175 cm\n\n\nLunghezza: 76 cm\n\n\nSpessore: 14 cm',
                en:'Wall mirror with curved plywood panel in oak veneered or lacquered with small shelves on the back. There is the possibility to apply the Plug In coat hooks (3 pcs).\n\nVA-537:\nHeight: 175 cm\nLength: 76 cm\nDepth: 14 cm'
            },
            n_images:2
        },
        {
            title:{
                it:'ALICANTE - SPECCHIO',
                en:'ALICANTE - MIRROR'
            },
            href:'linfa-design-alicante-m',
            descrizione:{
                it:'Specchiera Alicante M Linfa Design: specchiera con cornice in massello di rovere o noce canaletto. Disponibile in varie finiture. Disponibile anche nelle finiture laccate.\n\n\n\nDimensioni:\n\n\nAltezza 90 cm\n\n\nLarghezza 69 cm\n\n\nProfondità 3 cm',
                en:'Alicante M Linfa Design Mirror: mirror with solid oak or american walnut structure. Available in several finishes. Also available in matt lacquered finishes.\n\nDimensions:\nHeight 90 cm\nWidth 69 cm\nDepth 3 cm'
            },
            n_images:2
        }
    ],
    'appendiabiti':[
        {
            title:{
                it:'GRILLO - APPENDIABITI',
                en:'GRILLO - CLOTHES STAND'
            },
            href:'appendiabiti-grillo-linfadesign',
            descrizione:{
                it:'Appendiabiti in massello di rovere, disponibile laccato opaco. Anello con appendini supplementari in acciaio verniciato.\n\n\n\nAltezza: 173 cm.\n\n\nLarghezza: 35 cm.\n\n\nProfondità: 35 cm.',
                en:'Clothes stand in solid oak, available lacquered matt. Steel ring dark grey or white. \n\nHeight: 173 cm.\nWidth: 35 cm.\nDepth: 35 cm. '
            },
            n_images:3
        },
        {
            title:{
                it:'FLAUTO - APPENDIABITI',
                en:'FLAUTO - CLOTHES STAND'
            },
            href:'flauto-linfa-deisgn',
            descrizione:{
                it:'Appendi abito da parete in legno massello torniti con specchi sulla facciata pannello retro di supporto x fissaggio a muro usati separatamente oppure in composizione.\n\n\n\nVA-546:\n\n\nAltezza: 20 cm\n\n\nLunghezza: 20 cm\n\n\nProfondità: 10 cm',
                en:'Clothes stand in solid wood with mirrors on the front Back panel for wall fixing. To be used separately or in composition.\n\nVA-546:\nHeight: 20 cm\nLength: 20 cm\nDepth: 10 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'PLUG.IN - APPENDIABITI A MURO',
                en:'PLUG.IN - WALL CLOTHES STAND'
            },
            href:'plug-in-linfa-design',
            descrizione:{
                it:'Appendiabiti da parete in plexiglass con blocchetto in acciaio satinato.\n\n\n\nVA-529:\n\n\nAltezza: 5 cm\n\n\nDiam.: 10 cm',
                en:'Wall coat hook in plexiglass and satined steel.\n\nVA-529:\nHeight: 5 cm\nDiam.: 10 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'PLUG.IN PLUS - APPENDIABITI',
                en:'PLUG.IN PLUS - WALL CLOTHES STAND'
            },
            href:'plug-in-plus-linfa-design',
            descrizione:{
                it:'Appendiabito da parete in plexisiglas con blocchetto in acciaio satinato pannello retro in rovere o noce canaletto. Disponibile laccato opaco usati separatamente oppure in composizione.\n\n\n\nVA-530:\n\n\nAltezza: 20 cm\n\n\nLunghezza: 20 cm',
                en:'Wall coat stand with plexiglas and satined steel hook. Back panel in oak or american walnut. Available in mat lacquered. To be separately used or in multiple compositions.\n\nVA-530:\nHeight: 20 cm\nWidth: 20 cm'
            },
            n_images:2
        }
    ],
    'varie':[
        {
            title:{
                it:'KING - PORTA CD',
                en:'KING - CD HOLDER'
            },
            href:'king-linfadesign',
            descrizione:{
                it:'Porta-cd da parete in legno massello di rovere o noce canaletto, disponibile anche nelle finiture laccato opaco.\nCapacità 136 o 40 cd.\n\n\n\nVA-510\n\n\nDiametro: 50 cm.\n\n\nProfondità: 7 cm.\n\n\nCapacità 40 cd\n\n\n\nVA-509\n\n\nDiametro: 79 cm.\n\n\nProfondità: 7 cm.\n\n\nCapacità 136 cd',
                en:'Wall Cd-rack in solid oak or american walnut. Available mat laquered.Capacity 136 or 40 cds.\n\nVA-510\nDiameter: 50 cm.\nDepth: 7 cm.\nCapacity 40 cds\n\nVA-509\nDiameter: 79 cm.\nDepth: 7 cm.\nCapacity 136 cds'
            },
            n_images:4
        },
        {
            title:{
                it:'ADORNA - VASSOIO',
                en:'ADORNA - '
            },
            href:'adorna-tray-linfa-design',
            descrizione:{
                it:'Vassoio quadrato in massello di noce canaletto con intaglio sulla parte superiore.\n\n\n\nVA-557:\n\n\nAltezza: 3 cm\n\n\nLunghezza: 42 cm\n\n\nProfondità: 42 cm',
                en:'Square tray in solid american walnut with carving on the top.\n\nVA-557:\nHeight: 3 cm\nLength: 42 cm\nWidth: 42 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'IRIS - FIORIERA',
                en:'IRIS - FLOWER BOX'
            },
            href:'iris-linfa-design',
            descrizione:{
                it:'Fioriera in legno massello di rovere o noce canaletto, con vasca raccogli acqua in plastica. Disponibile laccata opaca.\n\n\n\nVA-512:\n\n\nAltezza: 39 cm\n\n\nLunghezza: 90 cm\n\n\nLarghezza: 45 cm\n\n\n\nVA-511:\n\n\nAltezza: 39 cm\n\n\nLunghezza: 40 cm\n\n\nLarghezza: 40 cm',
                en:'Flower box in solid oak or american walnut, with plastic water basin. Available mat lacquered.\n\nVA-512:\nHeight: 39 cm\nLength: 90 cm\nWidth: 45 cm\n\nVA-511:\nHeight: 39 cm\nLength: 40 cm\nWidth: 40 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'MYLIFE - PORTAFOTO',
                en:'MYLIFE - PICTURE FRAME'
            },
            href:'mylife-linfa-design',
            descrizione:{
                it:'Porta foto da parete o appoggio con cornice in legno massello di  rovere o noce canaletto. Disponibile laccata opaca.\n\n\n\nVA-551:\n\n\nAltezza: 47 cm\n\n\nLunghezza: 47 cm\n\n\nProfondità: 7,5 cm\n\n\n\nVA-552:\n\n\nAltezza: 47 cm\n\n\nLunghezza: 100 cm\n\n\nProfondità: 7,5 cm\n\n\n\nVA-553:\n\n\nAltezza: 100 cm\n\n\nLunghezza: 100 cm\n\n\nProfondità: 7,5 cm',
                en:'Picture-frame in solid oak or american walnut. Available mat lacquered.\n\nVA-551:\nHeight: 47 cm\nLength: 47 cm\nDepth: 7,5 cm\n\nVA-552:\nHeight: 47 cm\nLength: 100 cm\nDepth: 7,5 cm\n\nVA-553:\nHeight: 100 cm\nLength: 100 cm\nDepth: 7,5 cm'
            },
            n_images:2
        },
        {
            title:{
                it:'O.RAZIO - PORTA TV',
                en:'O.RAZIO - TV STAND'
            },
            href:'o-razio-linfa-design',
            descrizione:{
                it:'Porta tv in legno di rovere o noce canaletto. Disponibile laccato opaco.\n\n\n\nVA-524:\n\n\nAltezza: 25 cm\n\n\nLunghezza: 150 cm\n\n\nLarghezza: 40 cm',
                en:'TV Stand in oak or american walnut. Available mat lacquered.\n\nVA-524:\nHeight: 25 cm\nLength: 150 cm\nWidth: 40 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'SAT - VASSOIO',
                en:'SAT - TRAY'
            },
            href:'sat-linfa-design',
            descrizione:{
                it:'Vassoio in rovere, noce canaletto con maniglie cromate. Disponibile laccato opaco.\n\n\n\nVA-535:\n\n\nAltezza: 4 cm\n\n\nLunghezza: 42 cm\n\n\nLarghezza: 42 cm',
                en:'Tray in oak or american walnut with chromium plated handles. Available mat lacquered.\n\nVA-535:\nHeight: 4 cm\nLength: 42 cm\nWidth: 42 cm'
            },
            n_images:2
        },
        {
            title:{
                it:'WELCOME -CONSOLE',
                en:'WELCOME - CONSOLE'
            },
            href:'welcome-linfa-design',
            descrizione:{
                it:'Consolle con struttura in solido rovere, noce canaletto, o laccato opaco.\n\n\n\nVA-538:\n\n\nAltezza: 82 cm\n\n\nLunghezza: 100 cm\n\n\nLarghezza: 35 cm',
                en:'Consolle in oak, american walnut or mat lacquered structure.\n\nVA-538:\nHeight: 82 cm\nLength: 100 cm\nWidth: 35 cm'
            },
            n_images:1
        },
        {
            title:{
                it:'ZENIT - SVUOTA TASCHE DA MURO',
                en:'ZENIT - EMPTY POCKETS'
            },
            href:'empty-pockets-zenit-linfa-design',
            descrizione:{
                it:'Svuota tasche da parete massello di rovere o noce canaletto. Disponibile laccato opaco.\n\n\n\nVA-539:\n\n\nAltezza: 45 cm\n\n\nLunghezza: 24 cm\n\n\nProfondità: 27 cm',
                en:'Empty pockets wall solid oak or american walnut. Available mat lacquered.\n\nVA-539:\nHeight: 45 cm\nLength: 24 cm\nDepth: 27 cm'
            },
            n_images:1
        }
    ]
};

function getProdotto(language,categoria,_prodotto){
    var prodotto_item={...prodotto[categoria].find((item)=>{
        if(item.href===_prodotto) return item;
        else return false;
    })};

    let thumbnails=prodotto_item.n_images;

    prodotto_item.thumbnails=[];

    for(let i=0;i<thumbnails;i++){
        prodotto_item.thumbnails.push(i);
    }

    return prodotto_item;
}

const storia_azienda={
    it:{
        title:'LA NOSTRA STORIA',
        content:'La storia del Marchio Linfa nasce dalla cultura sviluppata nella lavorazione del legno, con la Falegnameria fondata dai bisnonni, tramandata ai figli, dal desiderio di evolverne gli impieghi e le interpretazioni. Una storia che inizia con le produzioni ispirate alle linee classiche, fino ad arrivare al Design attuale.\n\nTutto ciò conservando quei valori e quelle caratteristiche di lavorazione che rendono il Legno unico. Il noce, il rovere nelle varie interpretazioni e finiture, il faggio, materiali che arrivano da noi grezzi ed una volta essiccati e trattati prendono vita per diventare quello che oggi è Linfa.\n\nUna proposta di complementi di libera collocazione in grado di aggiungere valore agli ambienti della casa.'
    },
    en:{
        title:'OUR HISTORY',
        content:'Brand Linfa has its roots in the wood processing. Initially in fact Linfa was a carpentry, founded by great-grandparents and passed down to the children and to children\'s children: each generation helped evolving the art of wood manufacturintg, shifting from original classic lines up to nowadays modern design.\n\nDecades have passed by but one element has never changed: values and processing characteristics that make wood unique. Walnut, oak, beech in their various finishes and raw materials arrive and once dried and treated they come to their new life to become what Linfa Design is now.\n\nLinfa Design today offers a wide choice of furniture complements to add value to each room where a piece of furniture by Linfa Design is placed.'
    }
};

function novitaDatabase(language,novita,is_prodotti){
    var novita_item=novita_database.find((item)=>{
        if(item.href===novita) return item;
            else return false;
    });
    novita_item.images=[];
    for(let i=0;i<novita_item.images_n;i++){
        novita_item.images.push({
            id:'image_novita_dettaglio',
            className:'image_novita_dettaglio',
            src:is_prodotti?`/images/novita/prodotti/${novita}/${i}.jpg`:`/images/novita/eventi/${novita}/${i}.jpg`,
            alt:novita
        });
    }
    return novita_item;
}

const novita_database=[
    {
        href:'tavolo-london',
        it:'TAVOLO LONDON',
        en:'LONDON TABLE',
        images_n:1
    },
    {
        href:'tavolo-alicante',
        it:'TAVOLO ALICANTE',
        en:'ALICANTE TABLE',
        images_n:1
    },
    {
        href:'tavolo-bridge',
        it:'CONSOLE BRIDGE',
        en:'BRIDGE CONSOLE',
        images_n:1
    },
    {
        href:'salone-internazionale-del-mobile-2016',
        it:'SALONE INTERNAZIONALE DEL MOBILE 2016',
        en:'SALONE INTERNAZIONALE DEL MOBILE 2016',
        images_n:15
    },
    {
        href:'salone-internazionale-del-mobile-2015',
        it:'SALONE INTERNAZIONALE DEL MOBILE 2015',
        en:'SALONE INTERNAZIONALE DEL MOBILE 2015',
        images_n:15
    },
    {
        href:'tavolo-allungabile-kinesis',
        it:'TAVOLO ALLUNGABILE KÌNESIS',
        en:'KÌNESIS EXTENDIBLE TABLE',
        images_n:1
    },
    {
        href:'poltroncina-mia',
        it:'POLTRONCINA MIA',
        en:'POLTRONCINA MIA',
        images_n:2
    },
    {
        href:'tavolo-sintesi',
        it:'TAVOLO SINTESI',
        en:'SINTESI TABLE',
        images_n:1
    },
    {
        href:'cose-di-casa-2010',
        it:'COSE DI CASA 2010',
        en:'COSE DI CASA 2010',
        images_n:1
    },
    {
        href:'gagu-magazine-korea',
        it:'GAGU MAGAZINE - KOREA',
        en:'GAGU MAGAZINE - KOREA',
        images_n:2
    },
    {
        href:'cose-di-casa-2011',
        it:'COSE DI CASA 2011',
        en:'COSE DI CASA 2011',
        images_n:1
    },
    {
        href:'salone-internazionale-del-mobile-2009',
        it:'SALONE INTERNAZIONALE DEL MOBILE 2009',
        en:'SALONE INTERNAZIONALE DEL MOBILE 2009',
        images_n:7
    },
    {
        href:'salone-internazionale-del-mobile-2010',
        it:'SALONE INTERNAZIONALE DEL MOBILE 2010',
        en:'SALONE INTERNAZIONALE DEL MOBILE 2010',
        images_n:8
    },
    {
        href:'salone-internazionale-del-mobile-2011',
        it:'SALONE INTERNAZIONALE DEL MOBILE 2011',
        en:'SALONE INTERNAZIONALE DEL MOBILE 2011',
        images_n:8
    }
];

function getEvento(evento){
    var evento_item=novita_database.find((item)=>{
        if(item.href===evento) return item;
            else return false;
    });
    evento_item.slides=[];
    for(let i=0;i<evento_item.images_n;i++){
        evento_item.slides.push(
            {
                id:'slide_slideshow_homepage_'+i,
                className:'slide_slideshow_eventi',
                image_src:`/images/novita/eventi/${evento}/${i}.jpg`
            }
        );
    }
    return evento_item;
}

const contatti=[
    {
        it:'informazioni generali',
        en:'general informations'
    },
    {
        it:'direzione commerciale italia',
        en:'italy sales management'
    },
    {
        it:'direzione commeriale estero',
        en:'foreign sales management'
    },
    {
        it:'amministrazione',
        en:'administration'
    },
    {
        it:'ufficio tecnico',
        en:'technical office'
    },
    {
        it:'info@linfadesign.com',
        en:'info@linfadesign.com'
    },
    {
        it:'milena@linfadesign.com',
        en:'milena@linfadesign.com'
    },
    {
        it:'pierluigi@linfadesign.com',
        en:'pierluigi@linfadesign.com'
    },
    {
        it:'riccarda@linfadesign.com',
        en:'riccarda@linfadesign.com'
    },
    {
        it:'marco@linfadesign.com',
        en:'marco@linfadesign.com'
    },
    {
        it:'Richiedi informazioni',
        en:'Information Request'
    },
    {
        it:'Via Montello, 57 - 22060 - Cabiate - Italia - tel. +39 031768903 - fax +39 031756428',
        en:'Via Montello, 57 - 22060 - Cabiate - Italia - tel. +39 031768903 - fax +39 031756428'
    }
];

const rete_vendita={
    it:'IN COSTRUZIONE',
    en:'UNDER CONSTRUCTION'
};

const form={
    download:{
        title:{
            it:'CATALOGHI',
            en:'CATALOGS'
        },
        description:{
            it:'Compila il form sottostante per accedere alla pagina di download dei cataloghi',
            en:'Fill out the form below to access the download page of the catalogs'
        },
        inputs:[
            {
                it:'Nome',
                en:'Name'
            },
            {
                it:'Cognome',
                en:'Surname'
            },
            {
                it:'Email',
                en:'Email'
            }
        ]
    },
    contatti:{
        inputs:[
            {
                it:'Nome',
                en:'Name'
            },
            {
                it:'Cognome',
                en:'Surname'
            },
            {
                it:'Società',
                en:'Society'
            },
            {
                it:'Specificare la tipologia cliente',
                en:'Specify the customer typology',
                options:[
                    {
                        it:'Studio di Architettura',
                        en:'Architecture Studio'
                    },
                    {
                        it:'Interior Designer',
                        en:'Interior Designer'
                    },
                    {
                        it:'Buyer',
                        en:'Buyer'
                    },
                    {
                        it:'Reseller',
                        en:'Reseller'
                    },
                    {
                        it:'Individual',
                        en:'Individual'
                    },
                ]
            },
            {
                it:'Indirizzo email',
                en:'Email address'
            },
            {
                it:'Numero di telefono',
                en:'Phone number'
            },
            {
                it:'Messaggio',
                en:'Message'
            },
        ]
    }
};