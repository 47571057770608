import React from 'react';

import Header from '../containers/Header.jsx';
import Navigator from '../containers/Navigator.jsx';
import Footer from '../containers/Footer.jsx';
import Text from '../simple_components/Text.jsx';
import Slideshow from '../components/Slideshow.jsx';

import '../styles/EventiList.scss';

function EventiList(props){
    return(
        <>
            <Header setLanguage={props.setLanguage} {...props.header} />
            <Navigator {...props.navigator} />
            <div id={props.id} className={props.className}>
                {props.eventi.map((evento)=>(
                    <>
                        <div className={props.container_className}>
                            <Text {...evento.text} />
                        </div>
                        <Slideshow {...evento.slideshow} />
                    </>
                ))}
            </div>
            <Footer />
        </>
    );
}

export default EventiList;