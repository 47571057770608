import React,{useState} from 'react';

import Text from '../simple_components/Text.jsx';

import '../styles/Selector.scss';

function Selector(props){
    const [value,setValue]=useState(props.options[0].value);

        return(
            <div className={props.className}>
                <Text {...props.text} />
                <select id={props.id} className={props.selector_className} onChange={e=>{setValue(e.target.value);if(props.onChange!==undefined)props.onChange(e.target.value);}} defaultValue={value}>
                    {props.options.map((option)=>(
                        <option key={option.value} value={option.value}>{option.content}</option>
                    ))}
                </select>
            </div>
        );
}

export default Selector;