import React from 'react';

import Header from '../containers/Header.jsx';
import Footer from '../containers/Footer.jsx';
import Text from '../simple_components/Text.jsx';

import '../styles/RichiestaContattiSuccess.scss';

function RichiestaContattiSuccess(props){
    return(
        <>
            <Header setLanguage={props.setLanguage} {...props.header} />
            <div id={props.id} className={props.className}>
                <Text {...props.text} />
            </div>
            <Footer />
        </>
    );
}

export default RichiestaContattiSuccess;