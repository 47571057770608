import React from 'react';

import Header from '../containers/Header.jsx';
import Slideshow from '../components/Slideshow.jsx';
import Image from '../simple_components/Image.jsx';
import Footer from '../containers/Footer.jsx';

import '../styles/Homepage.scss';

function Homepage(props){
    return(
        <div id={props.id} className={props.className}>
            <Header {...props.header} setLanguage={props.setLanguage} />
            <Slideshow {...props.slideshow} />
            <div className={props.container_className}>
                <Image {...props.left_image} />
                <Image {...props.right_image} />
            </div>
            <Footer />
        </div>
    );
}

export default Homepage;