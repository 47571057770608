import React from 'react';

import Header from '../containers/Header.jsx';
import Navigator from '../containers/Navigator.jsx';
import Categoria from '../containers/Categoria.jsx';
import Footer from '../containers/Footer.jsx'

import '../styles/CategorieProdotto.scss';

function CategorieProdotto(props){
    return(
        <div id={props.id} className={props.className}>
            <Header {...props.header} setLanguage={props.setLanguage} />
            <Navigator {...props.navigator} />
            <div className={props.container_className}>
                {props.categorie.map((categoria,index)=>(<Categoria key={index} {...categoria} />))}
            </div>
            <Footer />
        </div>
    );
}

export default CategorieProdotto;