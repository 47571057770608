import React from 'react';

import Text from '../simple_components/Text.jsx';

import '../styles/Menu.scss';

function Menu(props){
    return(
        <>
            <div className='menu_mobile'>
                <Text onClick={(e)=>{if(document.getElementById('menu_header').classList.contains('open')){document.getElementById('menu_header').classList.remove('open')}else{document.getElementById('menu_header').classList.add('open')}}} id='menu_mobile' className='menu_mobile' content='MENU' type='p' />
            </div>
                <nav id={props.id} className={props.className}>
                    <ol id={props.id} className={props.menu_className}>
                        {props.options.map((option,index)=>(
                            <li key={index} className={props.submenu_title_className}>
                                <Text {...option[0]} />
                                <ul className={props.submenu_className}>
                                    {option.map((item,index_item)=>{
                                        if(index_item===0) return null;
                                            else return(
                                                <li key={index+'_'+index_item} className={props.submenu_item_className}><Text {...item} /></li>
                                            );
                                    })}
                                </ul>
                            </li>
                        ))}
                    </ol>
                </nav>
        </>
    );
}

export default Menu;