import React from 'react';

import Text from '../simple_components/Text.jsx';

import '../styles/Navigator.scss';

function Navigator(props){
    return(
        <div id={props.id} className={props.className}>
            {props.path.map((item,index)=>{
                return(<Text key={index} {...item} />);
            })}
        </div>
    );
}

export default Navigator;