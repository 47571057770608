import React from 'react';

import Header from '../containers/Header.jsx';
import Navigator from '../containers/Navigator.jsx';
import Footer from '../containers/Footer.jsx';
import ItemList from '../containers/Itemlist.jsx';

import '../styles/NewsList.scss';

function NewsList(props){
    return(
        <>
            <Header setLanguage={props.setLanguage} {...props.header} />
            <Navigator {...props.navigator} />
            <div id={props.id} className={props.className}>
                {props.novita.map((news)=>(<ItemList {...news} />))}
            </div>
            <Footer />
        </>
    );
}

export default NewsList;