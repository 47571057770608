import React from 'react';

import history from '../history.js';

import '../styles/Text.scss';

function Text(props){
    switch(props.type){
        case 'p':{
            if(props.href!==undefined){
                return(<div onClick={()=>{history.push(props.href);}} className={props.className+' a'}><p className={props.className}>{props.content}</p></div>);
            }else if(props.download!==undefined){
                return(<a href={props.download} download={props.download} className={props.className+' a'}><p className={props.className}>{props.content}</p></a>);
            }else{
                return props.content.split('\n').map((item,index)=>{return(<p onClick={props.onClick!==undefined?props.onClick:null} key={index} id={props.id} className={props.className}>{item}</p>)});
            }
        }
        case 'h2':{
            return(<h2 className={props.className}>{props.content}</h2>);
        }
        default:{
            return null;
        }
    }
}

export default Text;

/*
-menu_title
-menu_item
-submenu_item
-footer_title
-footer_content
-footer_link
-categoria_title
-product_list_item
-product_title
-product_description
-product_table_category
-product_table_value
-product_reviews
-storia_azienda_content
-news_item_title
-slideshow_title
-form_title
-form_content
-form_name
-form_link
-contatti_title
-contatti_content
-contatti_link
-language_selector
*/