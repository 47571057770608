import React from 'react';

import history from '../history.js';

import '../styles/Image.scss';

function Image(props){
    return(
        <img id={props.id} className={props.className} src={props.src} alt={props.alt} onClick={props.onClick!==undefined?props.onClick:props.href!==undefined?(e)=>{history.push(props.href);}:null} />
    );
}

export default Image;