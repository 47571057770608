import React from 'react';

import Image from '../simple_components/Image.jsx';
import Text from '../simple_components/Text.jsx';
import Slideshow from '../components/Slideshow.jsx';

import '../styles/ItemList.scss';

function ItemList(props){
    return(
        <div id={props.id} className={props.className}>
            {props.slideshow!==undefined?<Slideshow {...props.slideshow} />:<Image {...props.image} />}
            <div className={props.container_className}>
                <Text {...props.text} />
            </div>
        </div>
    );
}

export default ItemList;