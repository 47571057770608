import React from 'react';

function Table(props){
    return(
        <table id={props.id} className={props.className}>
            {props.data.map((row,row_index)=>(
                <tr key={row_index}>
                    {row.map((cell,cell_index)=>(
                        <td key={row_index+' '+cell_index} className={cell.className}>{cell.content}</td>
                    ))}
                </tr>
            ))}
        </table>
    );
}

export default Table;