import React,{useState} from 'react';

import Header from '../containers/Header.jsx';
import Navigator from '../containers/Navigator.jsx';
import Footer from '../containers/Footer.jsx';
import Text from '../simple_components/Text.jsx';
import Image from '../simple_components/Image.jsx';
import Table from '../components/Table.jsx';

import '../styles/ProdottoDetail.scss';

function ProdottoDetail(props){
    const [active,setActive]=useState({
        id:props.thumbnails[0].id,
        className:'image_main_prodotto_detail',
        src:props.thumbnails[0].src,
        alt:props.thumbnails[0].alt,
    });

    return(
        <div id={props.id} className={props.className}>
            <Header {...props.header} setLanguage={props.setLanguage} />
            <Navigator {...props.navigator} />
            <div className={props.container_className}>
                <div className={props.column_className+' left'}>
                    <div className={props.prodotto_title_div_className}>
                        <Text {...props.prodotto_title_text} />
                    </div>
                    <div className={props.prodotto_description_div_className}>
                        <Text {...props.prodotto_description_text} />
                    </div>
                    <div className={props.prodotto_table_div_className}>
                        <Table {...props.prodotto_table} />
                    </div>
                </div>
                <div className={props.column_className+' right'}>
                    <div className={props.prodotto_main_photo_div_className}>
                            <Image {...active} />
                    </div>
                    <div className={props.prodotto_thumbnails_div_className}>
                        {props.thumbnails.map((thumbnail,index)=>{
                            return(
                                <>
                                    <Image  {...thumbnail} onClick={(e)=>{setActive({
                                        id:thumbnail.id,
                                        className:'image_main_prodotto_detail',
                                        src:thumbnail.src,
                                        alt:thumbnail.alt,
                                    })}} />
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
            <Footer {...props.footer} />
        </div>
    );
}

export default ProdottoDetail;