import React,{useState,useEffect} from 'react';
import history from './history.js';
import {Router,Switch,Route,useRouteMatch,useParams} from 'react-router-dom';

import Homepage from './views/Homepage.jsx';
import CategorieProdotto from './views/CategorieProdotto.jsx';
import ProdottiCategoria from './views/ProdottiCategoria.jsx';
import ProdottoDetail from './views/ProdottoDetail.jsx';
import StoriaAzienda from './views/StoriaAzienda.jsx';
import NewsList from './views/NewsList.jsx';
import EventiList from './views/EventiList.jsx';
import NovitaList from './views/NovitaList.jsx';
import NewsDettaglio from './views/NewsDettaglio.jsx';
import EventoDettaglio from './views/EventoDettaglio.jsx';
import Press from './views/Press.jsx';
import DownloadCataloghi from './views/DownloadCataloghi.jsx';
import Contatti from './views/Contatti.jsx';
import ReteVendita from './views/ReteVendita.jsx';
import RichiestaContatti from './views/RichiestaContatti.jsx';
import Cataloghi from './views/Cataloghi.jsx';
import RichiestaContattiSuccess from './views/RichiestaContattiSuccess.jsx';


import content_file from './content.js';

import './App.scss';

function App() {
  const [language,setLanguage]=useState('it');
  const [content,setContent]=useState(content_file('it',setLanguage));
  
  useEffect(()=>setContent(content_file(language,setLanguage)),[language]);

  return (
    <div className='App'>
      <Router history={history}>
        <Switch>
          <Route path='/press'>
            <Press setLanguage={setLanguage} {...content.press} />
          </Route>
          <Route path='/download/cataloghi'>
            <Cataloghi setLanguage={setLanguage} {...content.download_cataloghi} />
          </Route>
          <Route path='/download'>
            <DownloadCataloghi setLanguage={setLanguage} {...content.download} />
          </Route>
          <Route path='/contatti/rete-vendita'>
            <ReteVendita setLanguage={setLanguage} {...content.rete_vendita} />
          </Route>
          <Route path='/contatti/richiesta-contatti/success'>
            <RichiestaContattiSuccess setLanguage={setLanguage} {...content.richiesta_contatti_success} />
          </Route>
          <Route path='/contatti/richiesta-contatti'>
            <RichiestaContatti setLanguage={setLanguage} {...content.richiesta_contatti} />
          </Route>
          <Route path='/contatti'>
            <Contatti setLanguage={setLanguage} {...content.contatti} />
          </Route>
          <Route path='/novita'>
            <Novita setLanguage={setLanguage} content={content} />
          </Route>
          <Route path='/azienda'>
            <StoriaAzienda setLanguage={setLanguage} {...content.storia_azienda} />
          </Route>
          <Route path='/prodotti'>
            <Prodotti setLanguage={setLanguage} content={content} />
          </Route>
          <Route path='/'>
            <Homepage {...content.homepage} setLanguage={setLanguage} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

function Prodotti(props){
  let match=useRouteMatch();

  return(
    <Switch>
        <Route path={`${match.path}/:categoria/:prodotto`}>
          <DettaglioProdotto setLanguage={props.setLanguage} content={props.content} />
        </Route>
        <Route path={`${match.path}/:categoria`}>
          <ProdottiInCategoria setLanguage={props.setLanguage} content={props.content} />
        </Route>
        <Route path={match.path}>
          <CategorieProdotto {...props.content.categorie_prodotto} setLanguage={props.setLanguage} />
        </Route>
    </Switch>
  );
}

function ProdottiInCategoria(props){
  let {categoria}=useParams();
  return(<ProdottiCategoria {...props.content.prodotti_categoria(categoria)} setLanguage={props.setLanguage} />);
}

function DettaglioProdotto(props){
  let {categoria,prodotto}=useParams();
  return(<ProdottoDetail {...props.content.prodotto_detail(categoria,prodotto)} setLanguage={props.setLanguage} />);
}

function Novita(props){
  let match=useRouteMatch();
  return(
    <Switch>
      <Route path={`${match.path}/prodotti/:novita`}>
        <DettaglioNovitaProdotti setLanguage={props.setLanguage} content={props.content} />
      </Route>
      <Route path={`${match.path}/eventi/:evento`}>
        <DettaglioEvento setLanguage={props.setLanguage} content={props.content} />
      </Route>
      <Route path={`${match.path}/prodotti`}>
        <NovitaList setLanguage={props.setLanguage} {...props.content.novita_prodotti} />
      </Route>
      <Route path={`${match.path}/eventi`}>
        <EventiList setLanguage={props.setLanguage} {...props.content.eventi} />
      </Route>
      <Route path={`${match.path}`}>
        <NewsList setLanguage={props.setLanguage} {...props.content.novita} />
      </Route>
    </Switch>
  );
}

function DettaglioNovitaProdotti(props){
  let {novita}=useParams();

  return(
    <NewsDettaglio setLanguage={props.setLanguage} {...props.content.novita_dettaglio(novita)} />
  );
}

function DettaglioEvento(props){
  let {evento}=useParams();

  return(
    <EventoDettaglio setLanguage={props.setLanguage} {...props.content.evento_dettaglio(evento)} />
  );
}

export default App;
