import React from 'react';

import Header from '../containers/Header.jsx';
import Navigator from '../containers/Navigator.jsx';
import Footer from '../containers/Footer.jsx';
import Text from '../simple_components/Text.jsx';
import Slideshow from '../components/Slideshow.jsx';

import '../styles/EventoDettaglio.scss';

function EventoDettaglio(props){
    return(
        <>
            <Header setLanguage={props.setLanguage} {...props.header} />
            <Navigator {...props.navigator} />
            <div id={props.id} className={props.className}>
                <div className={props.container_className}>
                    <Text {...props.text} />
                </div>
                <Slideshow {...props.slideshow} />
            </div>
            <Footer />
        </>
    );
}

export default EventoDettaglio;