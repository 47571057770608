import React from 'react';

import Selector from '../components/Selector.jsx';
import Image from '../simple_components/Image.jsx';
import Menu from '../components/Menu.jsx';

import '../styles/Header.scss';

function Header(props){
    return(
        <div id={props.id} className={props.className}>
            <Selector {...props.selector} onChange={props.setLanguage} />
            <Image {...props.image} />
            <Menu {...props.menu} />
        </div>
    );
}

export default Header;