import React from 'react';

import Text from '../simple_components/Text.jsx';

import '../styles/Footer.scss';

function Footer(props){
    return(
        <div className='footer'>
            <div className='footer_container'>
                <div className='footer_column left'>
                    <Text className='footer_title' content='LinfaDesign' type='h2' />
                    <Text className='footer_item' content='Via Montello, 57' type='p' />
                    <Text className='footer_item' content='22060 Cabiate (CO)' type='p' />
                    <Text className='footer_item' content='Rea CO-149787 - P.IVA 00575260138' type='p' />
                </div>
                <div className='footer_column right'>
                    <Text className='footer_title' content='Contatti' type='h2' />
                    <Text className='footer_item' content='Tel. +39 031 768903' type='p' />
                    <Text className='footer_item' content='Fax +39 031 756428' type='p' />
                    <Text className='footer_link' content='info@linfadesign.com' href='mailto:info@linfadesign.com' type='p' />
                </div>
            </div>
        </div>
    );
}

export default Footer;