import React from 'react';

import Header from '../containers/Header.jsx';
import Navigator from '../containers/Navigator.jsx';
import Footer from '../containers/Footer.jsx';
import Text from '../simple_components/Text.jsx';
import Image from '../simple_components/Image.jsx';

import '../styles/NovitaList.scss';

function NovitaList(props){
    return(
        <>
            <Header setLanguage={props.setLanguage} {...props.header} />
            <Navigator {...props.navigator} />
            <div id={props.id} className={props.className}>
                {props.novita.map((novita)=>(
                    <>
                        <div className={novita.container_className}>
                            <Text {...novita.text} />
                        </div>
                        {novita.images.map((image,index)=>(<Image key={index} {...image} />))}
                    </>
                ))}
            </div>
            <Footer {...props.footer} />
        </>
    );
}

export default NovitaList;